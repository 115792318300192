import CapitalNeeded from "./CapitalNeeded/CapitalNeeded";
import "./general/sass/style.scss";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom/dist";
import "./App.scss";
import ReactGA from "react-ga";
import {useEffect, useState} from "react";
import Examination from "./Examination/Examination";
import {investorTypeTestData} from "./Examination/data/investorTypeData";
import Landing from "./Landing/Landing";
import NoMatch from "./NoMatch/NoMatch";
import AnimatedLogo from "./general/component/AnimatedLogo";
import {appScriptUrls} from "./helper/appScripts";
import Quiz from "./Quiz/Quiz";
import {bondQuizData} from "./Quiz/data/bondQuizData";
import Main from "./Main/Main";
import SignUp from "./Auth/SignUp";
import Auth from "./Auth/Auth";
import {auth, db} from "./helper/firebaseHelper";
import {onAuthStateChanged} from "firebase/auth";
import {checkLocalStorageAndSetDefault, changeTheme, themeLocalStorageKey, themeValue} from "./helper/generalHelper";
import Transaction from "./Transaction/Transaction";
import AssetTracking from "./AseetTracking/AssetTracking";
import {doc, onSnapshot} from "firebase/firestore";
import {getUserDocID, initialSettings} from "./helper/settingsHelper";
import More from "./More/More";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const MEASUREMENT_ID = "G-9CPGWP47EF";
ReactGA.initialize(MEASUREMENT_ID);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState(themeValue.default);
  const [isUserChecked, setIsUserChecked] = useState(false);
  const [user, setUser] = useState(null);
  const [userSettings, setUserSettings] = useState({...initialSettings});

  useEffect( () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    checkLocalStorageAndSetDefault(themeLocalStorageKey, themeValue.default);

    setTimeout( () => {
      setIsLoading(false);
    }, 500);

    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        if(currentUser.emailVerified){
          //Get User Settings
          onSnapshot(doc(db, "settings", getUserDocID()), (doc) => {
            // console.log(doc.data());
            setUserSettings(doc.data());
          });
        }
      } else {
        setUser(null);
      }
      setIsUserChecked(true);
    });
  }, []);

  const onThemeButtonClick = () => {
    changeTheme().then( (res) => {
      setTheme(res);
    });
  }

  return (
    <div className="App">
      {(isLoading || !isUserChecked) ? (
        <div className={'loading'}>
          <AnimatedLogo/>
          <h5>불러오는 중입니다</h5>
        </div>
      ) : (
        <Router>
          <Routes>
            {/*<Route path={'/landing'} element={<Landing theme={theme} onThemeButtonClick={onThemeButtonClick}/>}/>*/}

            <Route path={'/'} element={<Main theme={theme} user={user} userSettings={userSettings} onThemeButtonClick={onThemeButtonClick}/>}>
              <Route path={""}
                     element={user === null ? (<Navigate to={"/auth"}/>) : <AssetTracking user={user} userSettings={userSettings}/>}
                     // element={<AssetTracking user={user} userSettings={userSettings}/>}
                     />
              <Route path={"transaction"} element={<Transaction/>}/>
              <Route path={'plan'} element={<CapitalNeeded theme={theme} feedbackAppScriptUrl={appScriptUrls.toolFeedback} onThemeButtonClick={onThemeButtonClick}/>}/>
              <Route path={'more'} element={<More theme={theme} user={user} onThemeButtonClick={onThemeButtonClick}/>}/>
            </Route>
            {/*<Route path={'/financial-freedom-calculator'} element={<CapitalNeeded theme={theme} feedbackAppScriptUrl={appScriptUrls.toolFeedback} onThemeButtonClick={onThemeButtonClick}/>}/>*/}

            <Route path={'/examination/investor-type'} element={<Examination theme={theme} onThemeButtonClick={onThemeButtonClick} data = {investorTypeTestData}><a className={"btn-fill c-el1"} href={"https://sailingcapitalism.com/%ed%88%ac%ec%9e%90-%ed%8f%ac%ed%8a%b8%ed%8f%b4%eb%a6%ac%ec%98%a4-%eb%82%b4%ea%b2%8c-%eb%a7%9e%eb%8a%94-%ec%9e%90%ec%82%b0-%ea%b5%ac%ec%84%b1%ec%9d%80/"} target='_blank'>내 성향에 맞는 자산 배분 전략은? 👀</a></Examination>}/>

            <Route path={'/sign-up'} element={<SignUp theme={theme}/>}/>
            <Route path={'/auth'} element={user === null ? (<Auth theme={theme}/>) : <Navigate to={"/"}/>}/>
            {/*<Route path={'/quiz/bond'} element={<Quiz theme={theme} data={bondQuizData}>*/}
              {/*<a className={"btn-fill c-el1"} href={"https://sailingcapitalism.com/%ed%88%ac%ec%9e%90-%ed%8f%ac%ed%8a%b8%ed%8f%b4%eb%a6%ac%ec%98%a4-%eb%82%b4%ea%b2%8c-%eb%a7%9e%eb%8a%94-%ec%9e%90%ec%82%b0-%ea%b5%ac%ec%84%b1%ec%9d%80/"} target='_blank'>내 성향에 맞는 자산 배분 전략은? 👀</a>*/}
            {/*</Quiz>}/>*/}
            <Route path={"*"} element={<NoMatch/>}/>
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
