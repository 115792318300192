import "./DropdownMenu.scss";
import {useState} from "react";

export const DropdownMenu = ({btnType = "fill", btnBase="none", btnShape="rect", toggleBtnIcon = "fa-solid fa-ellipsis",  children}) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className={`dropdown-menu ${openMenu ? 'open' : 'close'}`}>
      <button className={`dropdown-toggle btn-${btnType} c-${btnBase} s-${btnShape} ${toggleBtnIcon}`} onClick={() => {setOpenMenu(!openMenu)}}/>
      {openMenu ? (
        <div className={'dropdown-menu-container'}>
          <div className={'container-menu-items'}>
            {children}
          </div>
        </div>
      ) : ""}
    </div>
  )
}

export const DropdownMenuItem = ({icon, label, onClick, className, children}) => {
  return (
    <button className={`dropdown-menu-item btn-fill c-none s-sharp ${className}`} onMouseDown={onClick}>
      {icon ? (<i className={icon}/>) : ""}
      <span>{label}</span>
    </button>
  )
}
