import AuthForm from "./component/AuthForm";
import "./Auth.scss";

const Auth = () => {
  return (
    <div className={'auth page'}>
      <div className={'boundary'}>
        <h1>자본주의 항해 도구</h1>

        <AuthForm/>
        <div className={'intro'}>
          <p>자본주의 항해 도구가 뭔지 궁금하신가요?</p>
          <a className={'btn-fill s-pill c-el1 btn-learn-more'} href="https://metal-room-91b.notion.site/3e9ff9a509624b5ba0212ff2a417cd31">
            <i className="fa-solid fa-circle-info"/>
            <span>자본주의 항해 도구를 소개합니다!</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Auth;
