export default `
<html lang="" dir="ltr" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml"><head>
    <meta charset="utf-8">    
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=yes">
    <meta name="format-detection" content="telephone=no, date=no, address=no, email=no, url=no">
    <meta name="x-apple-disable-message-reformatting">
    <!--[if !mso]>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <![endif]-->
    <!--[if mso]>
    <style>
        * { font-family: sans-serif !important; }
    </style>
    <noscript>
        <xml>
            <o:OfficeDocumentSettings>
                <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
    </noscript>
    <![endif]-->
    <style type="text/css">
        /* Outlines the grids, remove when sending */
        /*table td { border: 1px solid cyan; }*/
        /* RESET STYLES */
        html, body, .document { margin: 0 !important; padding: 0 !important; width: 100% !important; height: 100% !important; }
        body { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-rendering: optimizeLegibility;}
        img { border: 0; outline: none; text-decoration: none;  -ms-interpolation-mode: bicubic; }
        table { border-collapse: collapse; }
        table, td { mso-table-lspace: 0pt; mso-table-rspace: 0pt; }
        body, table, td, a { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
        h1, h2, h3, h4, h5, p { margin:0;}
        *{word-break: keep-all}
        /* iOS BLUE LINKS */
        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
        /* ANDROID CENTER FIX */
        div[style*="margin: 16px 0;"] { margin: 0 !important; }
        /* MEDIA QUERIES */
        @media all and (max-width:639px){
            .wrapper{ width:100%!important; }
            .container{ width:100%!important; min-width:100%!important; padding: 0 !important; }
            .row{padding-left: 20px!important; padding-right: 20px!important;}
            .col-mobile {width: 20px!important;}
            .col{display: block!important; width: 100%!important;}
            .mobile-center{text-align: center!important; float: none!important;}
            .mobile-mx-auto {margin: 0 auto!important; float: none!important;}
            .mobile-left{text-align: center!important; float: left!important;}
            .img{ width:100% !important; height:auto !important; }
            .ml-btn { width: 100% !important; max-width: 100%!important;}
            .ml-btn-container { width: 100% !important; max-width: 100%!important;}
            *[class="mobileOff"] { width: 0px !important; display: none !important; }
            *[class*="mobileOn"] { display: block !important; max-height:none !important; }
            .mlContentTable{ width: 100%!important; min-width: 10%!important; margin: 0!important; float: none!important; }
            .mlContentButton a { display: block!important; width: auto!important; }
            .mlContentOuter { padding-bottom: 0px!important; padding-left: 15px!important; padding-right: 15px!important; padding-top: 0px!important; }
            .mlContentSurvey { float: none!important; margin-bottom: 10px!important; width:100%!important; }
            .multiple-choice-item-table { width: 100% !important; margin-bottom: 20px !important; min-width: 10% !important; float: none !important; }
            .ml-default, .ml-card, .ml-fullwidth { width: 100%; min-width: 100%; }
        }

        /* Carousel style */
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            .webkit {
                display: block !important;
            }
        }  @media screen and (-webkit-min-device-pixel-ratio: 0) {
            .non-webkit {
                display: none !important;
            }
        }  @media screen and (-webkit-min-device-pixel-ratio: 0) {
            /* TARGET OUTLOOK.COM */
            [class="x_non-webkit"] {
                display: block !important;
            }
        }  @media screen and (-webkit-min-device-pixel-ratio: 0) {
            [class="x_webkit"] {
                display: none !important;
            }
        }
    </style>
    
    <style type="text/css">@import url("https://assets.mlcdn.com/fonts-v2.css?version=1705921");</style>
<style type="text/css">
            @media screen {
                body {
                    font-family: Helvetica, sans-serif;
                }
            }
        </style></head>
<body style="margin: 0 !important; padding: 0 !important; background-color:#e6f2ff;" data-new-gr-c-s-check-loaded="14.1154.0" data-gr-ext-installed="">

    
        
        
    

    

        
            

            
            
            
            
            
            
        

        
            

            
        

        
            

            
        

    

    

        

        

        
            
            
            
            
            
        

        
            
            
            
            
            
        

        
            
            
            
            
            
        

        
            
            
            
            
            
        

        
            
            
            
            
            
        

        
            
            
            
            
            
        

        
            
            
            
            
        

        
            
            
        

        
            
            
            
        

        
            
            
            
            
            
            
            
            
            
            
        

        
            
            
            
            
            
            
            
            
            
            
        

        

            
                
                
                
                
                
            

            
                
                
                
            

            
                
                
                
                
                
            

            
                
                
                
                
                
            

            
                
                
                
                
                
            

            
                
                
                
                
            

            
                
                
            

            
                
                
            

        
    

    <div class="document" role="article" aria-roledescription="email" aria-label="" lang="" dir="ltr" style="background-color:#e6f2ff; line-height: 100%; font-size:medium; font-size:max(16px, 1rem);">

        <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t" if="variable.bodyBackgroundImage.value">
            <v:fill type="tile" src="" color="#e6f2ff"/>
        </v:background>
        <![endif]-->

        <div style="display: none; max-height: 0px; overflow: hidden;">
            
        </div>

        

        <table width="100%" align="center" cellspacing="0" cellpadding="0" border="0">
            <tbody><tr>
                <td class="" background="" align="center" valign="top">

                    <table class="container" align="center" width="640" cellpadding="0" cellspacing="0" border="0" style="max-width: 640px;">
    <tbody><tr>
        <td align="center">
            
        </td>
    </tr>
</tbody></table>


                    <table width="640" class="wrapper" align="center" border="0" cellpadding="0" cellspacing="0" style="
                        max-width: 640px;
                        
                        border-radius:8px; border-collapse: separate!important; overflow: hidden;
                        ">
                        <tbody><tr>
                            <td align="center">

                                
    
    
    <!--  -->
<table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0" cellpadding="0">
    <tbody><tr>
        <td style="">
            
                
                

                
                

                <table class="container ml-4 ml-default-border" width="640" bgcolor="#e6f2ff" align="center" border="0" cellspacing="0" cellpadding="0" style="
                width: 640px; min-width: 640px;
                ;
                
                ">
                    <tbody><tr>
                        <td class="ml-default-border container" height="20" style="line-height: 20px; min-width: 640px;"></td>
                    </tr>
                    <tr>
                        <td>

    
    

    
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
            <tbody><tr>
                <td class="row" style="padding: 0 50px; padding-top: 30px; line-height: 0;" align="center">
                    
                        <img src="https://storage.mlcdn.com/account_image/795118/dlL8jDSZfdvo4RS4nB4l4EwlAgeGZdjO5r9Ihe9r.png" border="0" alt="" width="130" class="" style="display: block;">
                    
                </td>
            </tr>
        </tbody></table>
    
    



                            </td>
                    </tr>
                    <tr>
                        <td height="20" style="line-height: 20px;"></td>
                    </tr>
                </tbody></table>
                
                    
                    
                
            
        </td>
    </tr>
</tbody></table>
<!--  -->


    <!--  -->
<table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0" cellpadding="0">
    <tbody><tr>
        <td style="">
            
                
                
                
                
                
                
                
                

                <table class="ml-default-border" width="100%" align="center" bgcolor="#e6f2ff" border="0" cellspacing="0" cellpadding="0" style="
                ;
                
                ">
                    <tbody><tr>
                        <td class="ml-default-border" background="" style="background-size: cover; background-position: center center;" valign="top" align="center">

                            
                            <table class="container ml-6" width="640" align="center" border="0" cellpadding="0" cellspacing="0" style="color: #333333; width: 640px; min-width: 640px;">
                                <tbody><tr>
                                    <td class="container" height="10" style="line-height: 10px; min-width: 640px;"></td>
                                </tr>
                                <tr>
                                    <td>

    
    







<table class="container" width="640" border="0" cellspacing="0" cellpadding="0">
    <tbody><tr>
        <td class="col-mobile" width="50" height="0" style="line-height: 0;"></td>
        <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                    <td>
                        <h2 style="font-family: 'Poppins', sans-serif; color: #09c269; font-size: 26px; line-height: 125%; font-weight: bold; font-style: normal; text-decoration: none; ;margin-bottom: 10px; margin-bottom: 0; text-align: center;"><strong>🎁 경제적 자유 계획서 도착!</strong></h2>
                    </td>
                </tr>
                <tr>
                    <td height="30" style="line-height:30px;"></td>
                </tr>
                
                
                <tr>
                    <td>
                        <h3 style="font-family: 'Poppins', sans-serif; color: #1b354f; font-size: 18px; line-height: 150%; font-weight: bold; font-style: normal; text-decoration: none; ;margin-bottom: 8px; text-align: center;"><strong><strong><strong>        안녕하세요 자본주의 항해의 시몬입니다!</strong></strong></strong></h3>
<p style="font-family: 'Poppins', sans-serif; color: #333333; font-size: 16px; line-height: 165%; margin-top: 0; margin-bottom: 10px; text-align: center;">경제적 자유 플래너를 사용해주셔서 감사해요!!</p>
<p style="font-family: 'Poppins', sans-serif; color: #333333; font-size: 16px; line-height: 165%; margin-top: 0; margin-bottom: 0; text-align: center;">자본주의 항해는 이제 100세 시대에 필수이지만, 여전히 어려운 투자 공부를 돕고자 만들어졌어요! 경제적 자유 달성을 위해서 좋은 자료들과 도구들로 찾아봴게요! 경제적 자유를 이루는 그 날 까지 함께 힘내봐요! 💪<br></p>
                    </td>
                </tr>
                
                <tr>
                    
                </tr>
                
                
            </tbody></table>
        </td>
        <td class="col-mobile" width="50" height="0" style="line-height: 0;"></td>
    </tr>
</tbody></table>


    
    
    
    
    



    
    
    
    
    



    
    
    
    
    



    
    
    
    
    



    
    
    
    



    
    






    
    
    
    
    
    
    
    
    
    
    








    
    
    
    
    
    
    
    
    
    







                                        </td>
                                </tr>
                                <tr>
                                    <td height="40" style="line-height: 40px;"></td>
                                </tr>
                            </tbody></table>

                            
                            
                                
                            

                        </td>
                    </tr>
                </tbody></table>

            
        </td>
    </tr>
</tbody></table>
<!--  -->


    
    
    <!--  -->
<table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0" cellpadding="0">
    <tbody><tr>
        <td style="">
            
                
                

                
                

                <table class="container ml-8 ml-default-border" width="640" bgcolor="" align="center" border="0" cellspacing="0" cellpadding="0" style="
                width: 640px; min-width: 640px;
                ;
                
                ">
                    <tbody><tr>
                        <td class="ml-default-border container" height="20" style="line-height: 20px; min-width: 640px;"></td>
                    </tr>
                    <tr>
                        <td>

    
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tbody><tr>
        <td class="row" style="padding: 0 50px;" align="center">
            <table role="presentation" cellpadding="0" cellspacing="0" border="0" align="center" width="100%">
                <tbody><tr>
                    <td height="1" style="border-top: 1px dashed #58d7b1; line-height: 1px;"></td>
                </tr>
            </tbody></table>
        </td>
    </tr>
</tbody></table>

    




                            </td>
                    </tr>
                    <tr>
                        <td height="20" style="line-height: 20px;"></td>
                    </tr>
                </tbody></table>
                
                    
                    
                
            
        </td>
    </tr>
</tbody></table>
<!--  -->


    <!--  -->
<table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0" cellpadding="0">
    <tbody><tr>
        <td style="">
            

                
                

                
                

                <table class="container ml-10 ml-default-border" width="640" bgcolor="" align="center" border="0" cellspacing="0" cellpadding="0" style="
                width: 640px; min-width: 640px;
                ;
                
                ">
                    <tbody><tr>
                        <td class="ml-default-border container" height="20" style="line-height: 20px; min-width: 640px;"></td>
                    </tr>
                    <tr>
                        <td class="row" style="padding: 0 50px;">


    
    


<table align="center" width="100%" border="0" cellspacing="0" cellpadding="0">
    
    
    
    
    <tbody><tr>
        <td align="center" style="text-align: center!important;">
            <h5 style="font-family: 'Poppins', sans-serif; color: #25C78B; font-size: 18px; line-height: 125%; font-weight: bold; font-style: normal; text-decoration: none; margin-bottom: 6px;"><a href="https://sailingcapitalism.com" target="_blank" style="color: #25C78B; font-weight: normal; font-style: normal; text-decoration: underline;"><strong>$ailing Capitalism</strong></a></h5>
        </td>
    </tr>
    <tr>
        <td align="center" style="text-align: center!important;">
            <div><p style="font-family: 'Poppins', sans-serif; color: #333333; font-size: 13px; line-height: 125%; display: inline-block; margin-bottom: 0;"><br></p></div>
<p style="font-family: 'Poppins', sans-serif; color: #333333; font-size: 13px; line-height: 125%; display: inline-block; margin-bottom: 0;">투자 공부를 쉽게</p>
        </td>
    </tr>
    <tr>
        <td height="12" style="line-height: 12px;"></td>
    </tr>
    <tr>
        <td align="center" style="text-align: center!important;">
            <p style="font-family: 'Poppins', sans-serif; color: #333333; font-size: 13px; line-height: 125%; display: inline-block; margin-bottom: 60px;">구독자 여러분의 건강한 투자 생활을 바랍니다!</p>
        </td>
    </tr>
    
    
</tbody></table>








    
    
    
    
    



    
    
    
    



    
    





    
    
    



                            </td>
                    </tr>
                    
                </tbody></table>
                
                    
                    
                
            
        </td>
    </tr>
</tbody></table>
                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        </tbody></table>
    </div>
</body></html>
`
