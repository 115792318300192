import {ASSET_TYPE, assetTypes, CURRENT_TYPE, currentTypes} from "../../helper/assetTrackingHelper";
import {CURRENCY_TYPE, currencyTypes, getCurrencyString} from "../../helper/currencyHelper";
import _ from "underscore";
import "./AssetTrackingTable.scss";
import {useEffect, useState} from "react";
import {addAsset, deleteAsset, addRecord, updateAsset, updateRecord, removeRecord} from "../../helper/assetTrackingHelper";
import CurrencyInput from "react-currency-input-field";
import {formatCurrency} from "../../helper/currencyHelper";
import {updateSettings} from "../../helper/settingsHelper";
import {Tooltip} from "react-tooltip";
import {DropdownMenuItem, DropdownMenu} from "../../general/component/DropdownMenu";
import Select from "../../general/component/Select";

const initialTmpAssetData = {
  name: "",
  type: ASSET_TYPE.STOCK,
  currency: CURRENCY_TYPE.KRW,
  currentType: CURRENT_TYPE.CURRENT
}

const colSpec = {
  name: {index: 0, width: 180},
  type: {index: 1, width: 100},
  currency: {index: 2, width: 120},
  currentType: {index: 3, width: 150},
  detail: {index: 4, width: 120}
}

const AssetTrackingTable = ({user, assets, recordSets, userSettings, assetToMainCurrencyExchangeRates, setDisplayDashboard}) => {
  const [tmpAssetData, setTmpAssetData] = useState({...initialTmpAssetData});
  const [sort, setSort] = useState({orderBy:"type", isAsc: true});
  const [tmpRecord, setTmpRecord]  = useState({});
  const [showHiddenAssets, setShowHiddenAssets] = useState(false);
  const [pinnedCols, setPinnedCols] = useState(["name", "detail"]);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [assetDropdownMenu, setAssetDropdownMenu] = useState({x: 0, y:0, asset: null});

  useEffect(() => {
    // console.log("asset tracking table user settings");
    // console.log(userSettings)
    initTmpRecord(assets)
  }, [assets])

  const initTmpRecord = (assets) => {
    let tmpRecordObj = {date: "", assets: {}};
    assets.map( (asset, index) => {
      tmpRecordObj.assets[asset.id] = {value: ""};
      if(asset.type === ASSET_TYPE.STOCK){
        tmpRecordObj.assets[asset.id].purchasePrice = "";
      }
    });
    setTmpRecord(tmpRecordObj);
  }

  const onAddAssetBtnClick = () => {
    addAsset(tmpAssetData).then( () => {initTmpAsset();});
  }
  const onAddRecordBtnClick = () => {
    addRecord(tmpRecord).then( () => {initTmpRecord(assets)});
  }

  const initTmpAsset = () => {
    let newTmpAsset = _.clone(initialTmpAssetData);
    newTmpAsset.currency = userSettings.mainCurrency;
    setTmpAssetData(newTmpAsset);
  }

  const updateTmpAssetData = (tmpAssetDataObj, key, value) => {
    let newData = _.clone(tmpAssetDataObj);
    newData[key] = value;
    setTmpAssetData(newData);
  }

  const updateAssetData = (asset, key, value) => {
    updateAsset(asset, key, value);
  }

  const sortAssets = (assetsToSort) => {
    return assetsToSort.sort( (a, b) => {
      let compareValueA = a[sort.orderBy];
      let compareValueB = b[sort.orderBy];

      if(sort.orderBy === "type"){
        compareValueA = assetTypes[a[sort.orderBy]].title;
        compareValueB = assetTypes[b[sort.orderBy]].title;
      }else if(sort.orderBy === 'currency'){
        compareValueA = currencyTypes[a.currency].title;
        compareValueB = currencyTypes[b.currency].title;
      }

      if(sort.isAsc){
        return compareValueA.localeCompare(compareValueB);
      }else{
        return compareValueB.localeCompare(compareValueA);
      }
    })
  }

  const updateSort = (orderBy) => {
    let newSort = _.clone(sort);
    if(newSort.orderBy === orderBy){
      newSort.isAsc = !newSort.isAsc;
    }else{
      newSort.orderBy = orderBy;
    }
    setSort(newSort);
  }

  const getPinLeftPos = (colType) => {
    let currentColSpec = colSpec[colType];
    let leftPos = 0;
    pinnedCols.map((pinnedColType, index) => {
      let pinnedColSpec = colSpec[pinnedColType];
      if(pinnedColSpec.index < currentColSpec.index){//현재 컬럼보다 index가 낮은 column들의 width만 더해주기
        leftPos += pinnedColSpec.width;
      }
    });
    return leftPos;
  }

  const getPinStyle = (colType) => {
    let colStyle = {
      minWidth: `${colSpec[colType].width}px`
    }
    if(pinnedCols.includes(colType)){
      colStyle = {
        ...colStyle,
        position: "sticky",
        left: getPinLeftPos(colType),
        zIndex: 10
      }
    }
    return colStyle;
  }

  const pinCol = (colType) => {
    if(pinnedCols.includes(colType)) setPinnedCols(pinnedCols.filter(pinnedColType => pinnedColType !== colType));
    else setPinnedCols([...pinnedCols, colType]);
  }

  const renderPinButton = (colType) => {
    return (
      <button className={`btn-fill c-none fa-solid fa-thumbtack btn-toggle-pin ${pinnedCols.includes(colType) ? 'pinned' : ''}`} onClick={() => {pinCol(colType)}}/>
    )
  }

  const renderColumnHeadBtn = (label, orderBy) => {
    return (
      <div className={'cell-content'}>
        {renderPinButton(orderBy)}
        <button className={'btn-ghost btn-sort'} onClick={ () => {updateSort(orderBy)}}>
          <span>{label}</span>
          {sort.orderBy === orderBy ? (
            sort.isAsc ? <i className="fa-solid fa-arrow-up"/> : <i className="fa-solid fa-arrow-down"/>
          ) : ""}
        </button>
        {orderBy === "currentType" ? (
          <>
            <a className={'tooltip-trigger title-wrapper'} data-tooltip-id={'current-type-tooltip'} data-tooltip-place="top">
              <i className="fa-solid fa-circle-info"/>
            </a>
            <Tooltip id={'current-type-tooltip'} className={'tooltip'} place={'top'}>
              <div className={'tooltip-content-wrapper'}>유동자산은 1년 안에 현금화해 사용가능한 자산을 말합니다</div>
            </Tooltip>
          </>
        ): ""}
      </div>
    )
  }

  const updateTmpRecord = (key, value, assetId) => {
    let newTmpRecord = _.clone(tmpRecord);
    if(assetId)
      newTmpRecord.assets[assetId][key] = value;
    else
      newTmpRecord[key] = value;
    setTmpRecord(newTmpRecord);
  }

  const getStockProfitRate = (asset) => {
    if(!asset) return 0; //이게 꼭 필요할까?

    let {value, purchasePrice} = asset;
    value = parseFloat(value);
    purchasePrice = parseFloat(purchasePrice);

    if(!value  || !purchasePrice || purchasePrice === 0) return 0;
    return ((value - purchasePrice)/purchasePrice * 100).toFixed(2);
  }

  const renderRecordValue = (recordSet, asset, valueKey) => {
    let assetCurrencySymbol = currencyTypes[asset.currency].symbol;
    let mainCurrencySymbol = currencyTypes[userSettings.mainCurrency].symbol;
    let assetCurrencyDecimalsLimit = currencyTypes[asset.currency].decimalsLimit;
    let mainCurrencyDecimalsLimit = currencyTypes[userSettings.mainCurrency].decimalsLimit;

    return (
      <div className={'cell-content record-value add-up'}>
        <button className={'btn-fill c-el3 btn-update-cell-value'} onClick={() => {
          let assetRecordValue = recordSet.assets[asset.id];
          let defaultValue = assetRecordValue ? assetRecordValue[valueKey] : "0";
          let val = window.prompt("수정 할 값을 입력해주세요", defaultValue);
          if(val !== null){
            if(!val){
              window.alert("값을 입력해주세요");
            }else{
              if(isNaN(val)){
                window.alert("숫자릅 입력해주세요");
              }else{
                let newAssets = _.clone(recordSet.assets);
                if(!newAssets[asset.id]) newAssets[asset.id] = {};
                newAssets[asset.id][valueKey] = parseFloat(val);
                updateRecord(recordSet, "assets", newAssets).then( () => {})
              }
            }
          }
        }}><i className={'fa-regular fa-edit icon-edit'}/></button>
        {
          (!recordSet.assets[asset.id] || recordSet.assets[asset.id][valueKey] === undefined) ? (
            <span>-</span>
          ) : (
            <>
              <span>{formatCurrency(recordSet.assets[asset.id][valueKey], assetCurrencyDecimalsLimit, assetCurrencySymbol)}</span>
              {asset.currency !== userSettings.mainCurrency ? (
                <span className={'sub'}>{formatCurrency(recordSet.assets[asset.id][valueKey] * assetToMainCurrencyExchangeRates[asset.currency], mainCurrencyDecimalsLimit, mainCurrencySymbol)}</span>
              ) : ""}
            </>
          )
        }
      </div>
    )
  }

  const updateUserSettings = (settingsData, key, value) => {
    updateSettings(key, value).then( () => {});
  }

  const renderCurrencyInput = (symbol, tmpRecordAssetKey, tmpRecordAsset, assetId ) => {
    if(!tmpRecordAsset) return null;

    return <CurrencyInput prefix={symbol} decimalsLimit={2} value={tmpRecordAsset[tmpRecordAssetKey] || ""}
                          placeholder={`${symbol} 입력`}
                          onValueChange={ (value, name, values) => {
                            updateTmpRecord(tmpRecordAssetKey, value, assetId);
                          }}/>
  }

  const renderSelect = (value, optionObj, updateFunc, dataObj, objKey) => {
    return (
      <Select value={value} onChange={(e) => {updateFunc(dataObj, objKey, e.target.value)}}>
        {_.keys(optionObj).map( (optionKey, index) => {
          return (
            <option value={optionKey} key={index}>
              {optionObj === currencyTypes ? getCurrencyString(optionKey) : `${optionObj[optionKey].emoji} ${optionObj[optionKey].title}`}
            </option>
          )
        })}
      </Select>
    )
  }

  const renderAssetToMainExchRates = () => {
    let mainCurrencyInfo =  currencyTypes[userSettings.mainCurrency];

    if(!_.keys(assetToMainCurrencyExchangeRates).length) return;
    return (
      <div className={'table-sub-header'}>
        <div className={'exchange-rates'}>
          <span className={'label'}>환율</span>
          <div className={'container-rates asset-currency-exchange-rates'}>
            {_.keys(assetToMainCurrencyExchangeRates).map( (assetCurrencyKey, index) => {
              if(assetCurrencyKey !== userSettings.mainCurrency)
                return (
                  <div className={'exchange-rate-item'} key={index}>
                    <div className={'item-header'}>
                      1 {assetCurrencyKey}
                    </div>
                    <div className={'item-body'}>
                      {formatCurrency(assetToMainCurrencyExchangeRates[assetCurrencyKey], mainCurrencyInfo.decimalsLimit, mainCurrencyInfo.symbol)}
                    </div>
                  </div>
                )
            })}
          </div>
        </div>
      </div>
    )
  }

  const renderSummaryValue = (recordSet, summaryBy, summaryType) => {
    let mainCurrencySymbol = currencyTypes[userSettings.mainCurrency].symbol;
    let mainCurrencyDecimalsLimit = currencyTypes[userSettings.mainCurrency].decimalsLimit;

    let value = summaryBy === "total" ? recordSet.summary[summaryBy] : recordSet.summary[summaryBy][summaryType];
    let formattedValue = formatCurrency(value, mainCurrencyDecimalsLimit, mainCurrencySymbol);

    if(summaryBy !== "total"){
      return `${formattedValue} (${ (recordSet.summary[summaryBy][summaryType]/recordSet.summary['total'] * 100).toFixed(2) }%)`
    }
    return formattedValue;
  }

  const renderEmptyCells = () => {
    return (
      <>
        <th style={{...getPinStyle('name')}}/>
        <th style={{...getPinStyle('type')}}/>
        <th style={{...getPinStyle('currency')}}/>
        <th style={{...getPinStyle('currentType')}}/>
      </>
    )
  }

  const setAssetDropdown = (e, asset) => {
    let tableRootElement = document.getElementsByClassName("asset-tracking-table")[0];

    let tableRootElementBoundingRect = tableRootElement.getBoundingClientRect();
    let x = e.clientX;
    let y = e.clientY - tableRootElementBoundingRect.top;
    // let y = e.clientY;
    setAssetDropdownMenu({x, y, asset})
  }

  const initAssetDropdownMenu = () => {
    setAssetDropdownMenu({x: 0, y: 0, asset: null});
  }

  return (
    <div className={'asset-tracking-table'} onClick={ () => {initAssetDropdownMenu()}}>
      {assetDropdownMenu.asset ? (
        <div className={'asset-dropdown-menu'} style={{left: assetDropdownMenu.x + "px", top: assetDropdownMenu.y + "px"}}>
          <div className={'container-menu-items'}>
            <DropdownMenuItem icon={"fa-regular fa-edit"} label={"이름 수정"} onClick={() => {
              let newName = window.prompt("자산 이름을 입력해주세요", assetDropdownMenu.asset.name);
              if(newName){updateAssetData(assetDropdownMenu.asset, "name", newName);}
            }}/>
            <DropdownMenuItem icon={`fa-regular fa-eye${assetDropdownMenu.asset.isHidden ? '' : '-slash'}`} label={assetDropdownMenu.asset.isHidden ? "숨김 해제" : "숨김"} onClick={() => {
              updateAssetData(assetDropdownMenu.asset, "isHidden", !assetDropdownMenu.asset.isHidden);
            }}/>
            <DropdownMenuItem icon={"fa-regular fa-trash-can"} label={"삭제"} onClick={() => {
              let confirm = window.confirm("정말 삭제하시겠어요?");
              if(confirm){deleteAsset(assetDropdownMenu.asset);}
            }}/>
          </div>
        </div>
      ) : ""}

      <div className={'table-header'}>
        <h1 className={'page-title'}>자산 현황</h1>

        <button className={'btn-show-options show-on-mobile btn-fill c-el1 s-pill fa-solid fa-gear'} onClick={() => {setDisplayOptions(true)}}/>
        <button className={'btn-show-dashboard show-on-mobile btn-fill c-el1 s-pill fa-solid fa-chart-simple'} onClick={() => {setDisplayDashboard(true)}}/>

        <div className={`main-options ${displayOptions ? 'show' : 'hide'}`}
             onClick={ () => {setDisplayOptions(false)}}>
          <div className={'option-content'} onClick={(e) => {e.stopPropagation()}}>
            <div className={'option-header show-on-mobile'}>
              <h2 className={'page-title'}>설정</h2>
              <button className={'btn-close btn-fill c-el1'} onClick={() => {setDisplayOptions(false)}}>닫기</button>
            </div>
            <div className={'option-body'}>
              <div className={"main-currency"}>
                <span>기준</span>
                {renderSelect(userSettings.mainCurrency, currencyTypes, updateUserSettings, userSettings, "mainCurrency")}
              </div>
              <button className={'btn-fill c-el1 btn-toggle-hidden-assets'} onClick={() => {setShowHiddenAssets(!showHiddenAssets);}}>
                <input type="checkbox" checked={showHiddenAssets} readOnly={true}/>
                <span>숨김 자산 표시</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {renderAssetToMainExchRates()}

      <div className={'table-body'}>
        <table>
          <thead>
          <tr>
            <th className={`name`} style={{...getPinStyle('name')}}>{renderColumnHeadBtn('이름', 'name')}</th>
            <th className={'type'} style={{...getPinStyle('type')}}>{renderColumnHeadBtn('타입', 'type')}</th>
            <th className={'currency'} style={{...getPinStyle('currency')}}>{renderColumnHeadBtn('통화', 'currency')}</th>
            <th className={'current-type'} style={{...getPinStyle('currentType')}}>{renderColumnHeadBtn('유동/비유동', 'currentType')}</th>
            <th className={'detail'} style={{...getPinStyle('detail')}}><div className={'cell-content'}>{renderPinButton('detail')}<span>세부사항</span></div></th>
            <td className={'new input'}><input type="date" onChange={(e) => {updateTmpRecord("date", e.target.value)}}/></td>
            {!recordSets.length && <td/>}
            {recordSets.map( (recordSet, index) => {
              return (
                <td key={index}>
                  <div className={'cell-content no-padding no-gap record-date'}>
                    <input type="date" value={recordSet.date} onChange={(e) => {updateRecord(recordSet, "date", e.target.value)}}/>
                    <button className={'btn-fill c-el3 btn-delete'}
                            onClick={() => {
                              let confirm = window.confirm(`${recordSet.date} 기록을 삭제하시겠어요?`);
                              if(confirm){
                                removeRecord(recordSet).then( () => {});
                              }
                            }}>
                      <i className={"fa-regular fa-trash-can"}/>
                    </button>
                  </div>
                </td>
              )
            })}
          </tr>
          </thead>
          <tbody>
          {
            sortAssets(assets).map( (asset, index) => {
              if(asset.isHidden && !showHiddenAssets) return "";

              let assetCurrency = currencyTypes[asset.currency];
              let tmpRecordAsset = tmpRecord.assets[asset.id];
              return (
                <>
                  <tr key={index}>
                    <th className={'name'} style={{...getPinStyle('name')}}>
                      <div className={'cell-content name'}>
                        <span className={'stretch'}>{asset.name}</span>
                        {asset.isHidden ? (<i className={'fa-regular fa-eye-slash'}/>) : ""}
                        <div className={'dropdown-menu'}>
                          <button className={`dropdown-toggle btn-fill c-none s-rect fa-solid fa-ellipsis`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setAssetDropdown(e, asset);
                                  }
                                  }/>
                        </div>

                      </div>
                    </th>
                    <th className={'type input'} style={{...getPinStyle('type')}}>{renderSelect(asset.type, assetTypes, updateAssetData, asset, "type")}</th>
                    <th className={'currency'} style={{...getPinStyle('currency')}}>{renderSelect(asset.currency, currencyTypes, updateAssetData, asset, "currency")}</th>
                    <th className={'current-type'} style={{...getPinStyle('currentType')}}>{renderSelect(asset.currentType, currentTypes, updateAssetData, asset, "currentType")}</th>
                    <th className={'detail add-up-value'} style={{...getPinStyle('detail')}}>{asset.type === ASSET_TYPE.STOCK ? "평가액" : "금액"}</th>
                    <td className={'new input'}>{renderCurrencyInput(assetCurrency.symbol, "value", tmpRecordAsset, asset.id)}</td>

                    {!recordSets.length && <td className={'filler-col'}/>}
                    {/*기록 날짜별 값을 넣어줌*/}
                    {recordSets.map( (recordSet, index) => {
                      return <td className={'add-up-value'} key={index}>{renderRecordValue(recordSet, asset, "value")}</td>
                    })}
                  </tr>
                  {
                    assetTypes[asset.type].isFloatingAsset ? ( //주식일 경우에는 매수액을 같이 입력하도록함
                      <>
                        <tr key={`${index}_purchase`}>
                          {renderEmptyCells()}
                          <th className={'detail'} style={{...getPinStyle('detail')}}>매수액</th>
                          <td className={'new input'}>
                            {renderCurrencyInput(assetCurrency.symbol, "purchasePrice", tmpRecordAsset, asset.id)}
                          </td>
                          {!recordSets.length && <td className={'filler-col'}/>}
                          {recordSets.map( (recordSet, index) => {
                            return <td key={index}>{renderRecordValue(recordSet, asset, "purchasePrice")}</td>
                          })}
                        </tr>
                        <tr key={`${index}_rate`}>
                          {renderEmptyCells()}
                          <th className={'detail'} style={{...getPinStyle('detail')}}>미실현 손익</th>
                          <td className={'new input sub'}><div className={'cell-content record-value rate'}>{getStockProfitRate(tmpRecordAsset)}%</div></td>
                          {!recordSets.length && <td className={'filler-col'}/>}
                          {recordSets.map( (record, index) => {
                            return <td className={'sub'} key={index}><div className={'cell-content record-value rate'}>{getStockProfitRate(record.assets[asset.id])}%</div></td>
                          })}
                        </tr>
                      </>
                    ) : ""
                  }
                </>
              )
            })
          }
          <tr className={'filler-row'}>
            {renderEmptyCells()}
            <th className={'detail'} style={{...getPinStyle('detail')}}/>
            <td className={'new input'}/>
            {!recordSets.length && <td className={'filler-col'}/>}
            {recordSets.map( (record, index) => {
              return <td className={'sub'} key={index}/>
            })}
          </tr>

          <tr className={'input'}>
            <td className={'input'}>
              <input type="text" value={tmpAssetData.name} placeholder={"자산 이름을 입력해주세요"}
                     onChange={(e) => {updateTmpAssetData(tmpAssetData, 'name', e.target.value)}}/>
            </td>
            <td className={'input'}>
              {renderSelect(tmpAssetData.type, assetTypes, updateTmpAssetData, tmpAssetData, "type")}
            </td>
            <td className={'input'}>
              {renderSelect(tmpAssetData.currency, currencyTypes, updateTmpAssetData, tmpAssetData, "currency")}
            </td>
            <td className={'input'}>
              {renderSelect(tmpAssetData.currentType, currentTypes, updateTmpAssetData, tmpAssetData, "currentType")}
            </td>
            <td className={'input'}>
              <button className={'btn-fill c-sailing'} disabled={!tmpAssetData.name} onClick={() => {onAddAssetBtnClick()}}>자산 추가</button>
            </td>
            <td className={'new input'}>
              <button className={'btn-fill c-sailing'} disabled={!tmpRecord.date} onClick={() => {onAddRecordBtnClick()}}>현황 기록</button>
            </td>
            {!recordSets.length && <td/>}
            {recordSets.map( (recordSet, index) => {return <td key={index}/>})}
          </tr>
          </tbody>

          <tfoot>
          <tr>
            <td>총 자산</td><td colSpan={5}/>
            {!recordSets.length && <td/>}
            {recordSets.map( (recordSet, index) => {return <td key={index}><div className={'cell-content record-value'}>{renderSummaryValue(recordSet, 'total')}</div></td>})}
          </tr>
          <tr>
            <td>주식 자산</td><td colSpan={5}/>
            {!recordSets.length && <td/>}
            {recordSets.map( (recordSet, index) => {return <td key={index}><div className={'cell-content record-value'}>{renderSummaryValue(recordSet, "byAsset", ASSET_TYPE.STOCK)}</div></td>})}
          </tr>
          <tr>
            <td>유동 자산</td><td colSpan={5}/>
            {!recordSets.length && <td/>}
            {recordSets.map( (recordSet, index) => {return <td key={index}><div className={'cell-content record-value'}>{renderSummaryValue(recordSet, 'byCurrent', CURRENT_TYPE.CURRENT)}</div></td>})}
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default AssetTrackingTable;
