const Transaction = () => {
  return (
    <div className={'transaction page'}>
      <div className={'boundary'}>
        <h1>Transaction</h1>
      </div>
    </div>
  )
}

export default Transaction;
