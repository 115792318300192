const Loader = () => {
  return (
    <div className={'loader'}>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
      <div className={'loader-stick'}/>
    </div>
  )
}

export default Loader;
