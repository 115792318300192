import "./More.scss";
import {logout} from "../helper/firebaseHelper";
import {Link, useNavigate} from "react-router-dom";
import FeedbackForm from "../general/component/FeedbackForm";

const More = ({user, onThemeButtonClick}) => {
  const navigate = useNavigate();

  const navigateTo = (toLink) => {
    navigate(toLink);
  }

  return (
    <div className={'more page'}>
      <div className={'boundary'}>
        <h1 className={'page-title'}>더보기</h1>
        {user && (<div className={'email-info'}>{user.email}</div>)}
        <button className={'btn-fill s-pill c-el1 btn-more-menu'} onClick={onThemeButtonClick}>
          <i className={'fa-solid fa-circle-half-stroke'}/>
          <span>테마 변경</span>
        </button>
        {!user && (
          <button className={'btn-fill s-pill c-el1 btn-more-menu'} onClick={() => {navigateTo('/auth')}}>
            <i className={'fa-solid fa-arrow-right-from-bracket'}/>
            <span>로그인</span>
          </button>
        )}
        { user && (
          <button className={'btn-fill s-pill c-el1 btn-more-menu'} onClick={() => {logout()}}>
            <i className={'fa-solid fa-arrow-right-from-bracket'}/>
            <span>로그아웃</span>
          </button>
        )}
        <div className={'container-etc'}>
          <a className={'btn-fill s-pill c-el1 btn-more-menu'} href="https://metal-room-91b.notion.site/3e9ff9a509624b5ba0212ff2a417cd31">
            <i className="fa-solid fa-circle-info"/>
            <span>자본주의 항해 도구 소개</span>
          </a>
          <FeedbackForm/>
        </div>

      </div>
    </div>
  )
}

export default More;
