import "./Main.scss";
import NavBar from "../general/component/NavBar";
import NotVerifiedYet from "../Auth/component/NotVerifiedYet";
import {Outlet} from "react-router-dom/dist";

const Main = ({user, theme, userSettings, onThemeButtonClick}) => {
  // if(!user.emailVerified) return <NotVerifiedYet user={user}/>;
  return (
    <div className={'main page'}>
      <NavBar theme={theme} user={user} onThemeButtonClick={onThemeButtonClick}/>
      <div className={'main-content'}>
        <Outlet/>
      </div>
    </div>
  )
}

export default Main;
