export default {
  name: "이름",
  type: "타입",
  currency: "통화",
  currentType: "유동/비유동",
  detail: "세부사항",
  byAsset: "자산별",
  byCurrent: "유동성별",
  byCurrency: "통화별"
}
