const quizType = {
  OX: "OX",
  CHOICE: "CHOICE",
  MULTI_CHOICE: "MULTI_CHOICE",
  SHORT_ANSWER: "SHORT_ANSWER"
}

const questions = [
  {
    type: quizType.CHOICE,
    question: "채권을 구매한다는 것은 무엇을 의미하나요?",
    answers: [
      {label: "주식을 구매하는 것과 같다", isAnswer: false},
      {label: "발행 주체에게 자금을 빌려주는 것이다", isAnswer: true},
      {label: "발행 주체로부터 물건을 구매하는 것이다", isAnswer: false},
      {label: "기업에 직접 투자하는 것이다", isAnswer: false},
    ],
    explanation: "채권 구매는 발행 주체(정부, 기업 등)에게 자금을 빌려주고, 대신 이 채권을 통해 약정된 기간 동안 이자를 받는 것을 의미합니다."
  },
  {
    type: quizType.CHOICE,
    question: "채권의 안정성에 대해 어떻게 일반적으로 인식되어 있나요?",
    answers: [
      {label: "주식보다 덜 안정적이다", isAnswer: false},
      {label: "주식과 동일한 수준의 안정성을 가진다", isAnswer: false},
      {label: "주식보다 안정적이라고 인식된다", isAnswer: true},
      {label: "모든 채권이 높은 위험을 가진다", isAnswer: false},
    ],
    explanation: "채권은 발행 기관의 신용도에 따라 위험이 달라지지만, 일반적으로 주식에 비해 더 안정적인 투자 수단으로 인식됩니다."
  },
  {
    type: quizType.CHOICE,
    question: "채권의 구성요소 중 '표면 금리'는 무엇을 의미하나요?",
    answers: [
      {label: "채권을 처음 구매할 때 지불해야 하는 금액", isAnswer: false},
      {label: "채권을 판매할 때 얻을 수 있는 수익률", isAnswer: false},
      {label: "채권이 제공하는 연간 이자 비율", isAnswer: true},
      {label: "만기일까지의 총 수익금", isAnswer: false},
    ],
    explanation: "표면 금리는 채권이 제공하는 연간 이자 비율을 의미하며, 채권 보유자가 받게 되는 이자의 비율입니다."
  },
  {
    type: quizType.CHOICE,
    question: "정부와 기업이 발행하는 채권 중 어느 것이 일반적으로 더 안정적인가요?",
    answers: [
      {label: "기업채", isAnswer: false},
      {label: "국채", isAnswer: true},
      {label: "둘 다 비슷하다", isAnswer: false},
      {label: "안정성은 발행 주체에 따라 달라지지 않는다", isAnswer: false},
    ],
    explanation: "일반적으로 국가가 발행하는 국채는 기업채에 비해 더 안정적으로 여겨집니다. 국가의 신용도는 기업보다 높게 평가되며, 디폴트(채무 불이행) 위험이 낮습니다."
  },
  {
    type: quizType.CHOICE,
    question: "만기 기간이 길어질수록 채권의 수익률은 어떻게 변하나요?",
    answers: [
      {label: "더 낮아진다", isAnswer: false},
      {label: "변동성이 적어져서 안정적이 된다", isAnswer: false},
      {label: "더 높아진다", isAnswer: true},
      {label: "만기 기간과 수익률은 관련이 없다", isAnswer: false},
    ],
    explanation: "만기 기간이 길어질수록 채권의 수익률은 일반적으로 더 높아집니다. 이는 긴 기간 동안 금리 변동성이나 인플레이션 등의 위험에 노출될 가능성이 높기 때문입니다."
  },
  {
    type: quizType.OX,
    question: "채권은 주식과 마찬가지로 소유권을 나타내는 금융 자산이다.",
    answers: [
      {label: "O", isAnswer: false},
      {label: "X", isAnswer: true},
    ],
    explanation: "채권은 빚 증서로, 채권을 구매함으로써 발행 주체에게 자금을 빌려주는 행위를 의미합니다."
  },
  {
    type: quizType.OX,
    question: "채권의 표면 금리는 시장 금리에 따라 변동하지 않는다.",
    answers: [
      {label: "O", isAnswer: true},
      {label: "X", isAnswer: false},
    ],
    explanation: "표면 금리는 채권이 발행될 때 결정되며, 채권의 만기까지 변하지 않습니다."
  },
  {
    type: quizType.OX,
    question: "모든 채권은 만기가 동일하다.",
    answers: [
      {label: "O", isAnswer: false},
      {label: "X", isAnswer: true},
    ],
    explanation: "채권은 발행 기관, 목적, 조건에 따라 다양한 만기 기간을 가집니다."
  },
  {
    type: quizType.OX,
    question: "정부가 발행하는 채권을 구입하는 것은 정부에 대한 투자로 간주될 수 있다.",
    answers: [
      {label: "O", isAnswer: true},
      {label: "X", isAnswer: false},
    ],
    explanation: "정부 채권을 구입하는 행위는 정부에 자금을 빌려주는 것으로 간주되며, 이는 일종의 투자 행위로 볼 수 있습니다."
  },
  {
    type: quizType.OX,
    question: "채권의 이자 지급 주기는 항상 연간 단위로만 이루어진다.",
    answers: [
      {label: "O", isAnswer: false},
      {label: "X", isAnswer: true},
    ],
    explanation: "채권의 이자 지급 주기는 발행 조건에 따라 다양할 수 있습니다. 연간 단위뿐만 아니라, 반기별, 분기별 또는 다른 주기로 이자가 지급될 수 있습니다."
  }
]

const kakaoShareData = {
  title: '채권의 기본!',
  description: '채권의 기본에 대한 퀴즈입니다1',
  thumbnailImage: "https://ifh.cc/g/xmBAvl.jpg",
  buttonText: "퀴즈 풀기!",
  link: "https://tools.sailingcapitalism.com/quiz/bond"
}

export const bondQuizData = {
  title: "채권의 기본!",
  description: "채권의 기본에 대한 퀴즈입니다!",
  intro: <span>채권 <br/>나는 얼마나 알고있나?</span>,
  coverImage : require("../../assets/img/investor_type_cover_optimized.jpg"),
  kakaoShareData : kakaoShareData,
  types: quizType,
  questions: questions,
  appScriptSheetName: "bond"
}
