import "./NeedVerification.scss";
import {useState} from "react";
import {sendVerificationEmail} from "../../helper/firebaseHelper";

const NeedVerification = ({user}) => {
  const [emailSent, setEmailSent] = useState(false);

  return (
    <div className={'need-verification'}>
      <h2>이메일을 인증해주세요!</h2>
      <div className={'user-email'}>
        {user.email}
      </div>
      <p>서비스 이용을 위해서는 이메일 인증이 필요해요!</p>
      {emailSent ? (
        <>
          <p>이메일이 전송되었습니다!</p>
          <p>메일함을 확인해주세요!</p>
        </>
      ) : (
        <>
          <p>이메일이 안왔나요?</p>
          <button className={'btn-fill c-el1 s-pill'}
                  onClick={() => {sendVerificationEmail().then(() => {setEmailSent(true)})}}>인증 메일 다시 보내기</button>
        </>
      )}

    </div>
  )
}

export default NeedVerification;
