import "./AuthForm.scss";
import {login, signUp, sendPassRestEmail} from "../../helper/firebaseHelper";
import {useState} from "react";
import Loader from "../../general/component/Loader";
import {useNavigate} from "react-router-dom/dist";
import {Link} from "react-router-dom";

const AuthForm = () => {
  const [slide, setSlide] = useState("login");
  const [isLoading, setIsLoading] = useState(false);
  const [isPassResetEmailSent, setIsPassResetEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onLoginButtonClick = (e) => {
    login(e).then( (res) => {
      setError(null);
      navigate("/");
    }).catch((error) => {
      setError({type: "login", message: "이메일과 비밀번호를 확인해주세요"});
    });
  }

  const initError = () => {
    setError(null);
  }

  const onSignUpButtonClick = (e) => {
    setIsLoading(true);
    signUp(e).then( () => {
      setIsLoading(false);
    });
  }

  const onResetPassEmailBtnClick = (e) => {
    setIsLoading(true);

    sendPassRestEmail(e).then( () => {
      setIsLoading(false);
      setIsPassResetEmailSent(true);
    });
  }

  return (
    <div className={`auth-form ${slide}`}>
      {isLoading ? <Loader></Loader> : ""}

      <div className={'container-slides'}>
        <div className={'slide'}>
          <div className={'slide-header'}>
            <h2>로그인</h2>
          </div>
          <form onSubmit={onLoginButtonClick}>
            <input type="email" name={'email'} placeholder={"이메일을 입력해주세요"} onChange={initError}/>
            <input type="password" name={'password'} placeholder={"비밀번호를 입력해주세요"} onChange={initError}/>
            {error && error.type === "login" ? (
              <div className={'error'}>{error.message}</div>
            ) : ""}

            <button className={'btn-fill c-sailing'} type={"submit"}>로그인</button>
          </form>

          <button className={'btn-fill c-el1'} onClick={() => {setSlide('password-reset')}}>비밀번호를 잊으셨나요?</button>
          <button className={'btn-fill c-sailing'} onClick={() => {setSlide('signup')}}>가입하기</button>
        </div>

        <div className={'slide'}>
          <div className={'slide-header'}>
            <h2>가입하기</h2>
          </div>

          <form onSubmit={onSignUpButtonClick}>
            <input type="email" name={'email'} placeholder={"이메일을 입력해주세요"} onChange={initError}/>
            <input type="password" name={'password'} placeholder={"비밀번호를 입력해주세요"} onChange={initError}/>
            <button className={'btn-fill c-sailing'} type={"submit"}>회원가입</button>
          </form>

          <div className={'slide-footer'}>
            <button className={'btn-fill c-el1'} onClick={ () => {setSlide('login')}}>뒤로</button>
          </div>
        </div>

        <div className={'slide'}>
          <div className={'slide-header'}>
            <h2>비밀번호 재설정</h2>
          </div>

          {isPassResetEmailSent ? (
            <div className={'email-sent'}>
              <span>이메일이 전송되었습니다!</span>
              <span>메일함을 확인해주세요!</span>
            </div>
          ) : (
            <form onSubmit={onResetPassEmailBtnClick}>
              <input type="email" name={'email'} placeholder={"이메일을 입력해주세요"} onChange={initError}/>
              <button className={'btn-fill c-sailing'} type={"submit"}>비밀번호 재설정 메일 보내기</button>
            </form>
          )}


          <div className={'slide-footer'}>
            <button className={'btn-fill c-el1'} onClick={ () => {setSlide('login')}}>뒤로</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthForm;
