import axios from "axios";

export const subscribe = (email, page) => {
  let headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiOTRhMTVjNzFhN2IyNjMzZDA2MTMxYjU3YmM0M2U3OTk3ZmJhNzExOTkxOGQ5MzE5MDU5OTM5NWU3MjQ4NzI2MmY4ZWY0NjRmZjE0NGE1NWIiLCJpYXQiOjE3MDY0Mjk5NTMuOTQ0NDUzLCJuYmYiOjE3MDY0Mjk5NTMuOTQ0NDU1LCJleHAiOjQ4NjIxMDM1NTMuOTQwMzA2LCJzdWIiOiI4MTAwNjAiLCJzY29wZXMiOltdfQ.fl3NxWKdQ73V3dcWI9PfmgxKEZQfGHDlc0hT9NGC_XAeii65FQKVHqCeOG-SR5CV8tOj2AEjFqlVufuX6QLinvvEhn9UR6szgv_rStUo8cRUvjYidgv0nKK2ZjCe8jcJvG8TrRJOsJHxf5vVSJBIAq6Q6arFWEVgMU3wYP83ezX6lMA5yEw8nfK9J3O6W7uuCduexpZvtWwh6s_HMeCA-HWDZ9mwHSwxDhlpLQezmarY9sL1uVwebgDe00kU4XiMNRzfWDSw05plLZe30mF4mEiRaNJaEhCLnEev0ODNPjiVCkL5msIf_9CIOZFWfrWjZoFjIvPjzwzdr5FQMxJxY_nTlrmg8THJaPW4bCzAjTGdMwYsg7w6OS6PBM4KBl55qO1uY5N-5beArvPZIIuNbeoqSS1S9bjfUn4G49BgPLh9eiiSIrmrmtckzM8sdT1qspTPlyupovotyj0rn1qn2e01Wt4xfkL5BYVDYGQd6XJ7Qn4dltG0MgD4lTQE0eZx7oFqlzUnC2lEFAe8BxoShfl-rI37GUKhCgixrMLMwnym1jRrWmatE4ePqfTlsAe0zLhX9GVR7HE6jDkx7v3m9NJ_IhuofAGFPgKVaGozvHsk1gcaG5CA-TBkPCYuFD6bIrDACu4RpTmkiO4vdzJoBb_12Rt4KAKIMxc3Wau4STo"
  }
  return new Promise( (resolve, reject) => {
    axios.post(
      "https://connect.mailerlite.com/api/subscribers",
      {
        "email": email,
        "fields": {
          "page": page
        },
        "groups": [
          "111600383391958184",
        ]
      }, {headers: headers}).then( () => {
        resolve({message: "done"});
    })
  })
}
