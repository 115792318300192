import {signUp} from "../helper/firebaseHelper";

const SignUp = () => {
  let email = window.localStorage.getItem("emailForSignIn");

  return (
    <div className={'sign-up'}>
      {email ? (
        <form action={signUp}>
          <input type="email" placeholder={"이메일을 입력해주세요"} value={email} disabled={true}/>
          <input type="password" placeholder={"비밀번호를 입력해주세요"}/>
          <button className={'btn-fill c-sailing'} type={"submit"}>가입 완료</button>
        </form>
      ) : (
        <h1>잘못된 접근입니다</h1>
      )}
    </div>
  )
}

export default SignUp;
