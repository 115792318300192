import axios from "axios";

export const CURRENCY_TYPE = {
  KRW: "KRW",
  USD: "USD",
  EUR: "EUR",
  JPY: "JPY",
  GBP: "GBP",
  CHF: "CHF",
  CAD: "CAD",
  AUD: "AUD",
  CNY: "CNY",
  NZD: "NZD",
  SGD: "SGD",
}

export const currencyTypes = {
  "KRW": {"title": "한국", "symbol": "￦", decimalsLimit: 0, emoji:"🇰🇷"},
  "USD": {"title": "미국", "symbol": "$", decimalsLimit: 2, emoji: "🇺🇸"},
  "EUR": {"title": "유로", "symbol": "€", decimalsLimit: 2, emoji: "🇪🇺"},
  "JPY": {"title": "일본", "symbol": "¥", decimalsLimit: 2, emoji: "🇯🇵"},
  "GBP": {"title": "영국", "symbol": "£", decimalsLimit: 2, emoji: "🇬🇧"},
  "CHF": {"title": "스위스", "symbol": "CHF", decimalsLimit: 2, emoji: "🇨🇭"},
  "CAD": {"title": "캐나다", "symbol": "$", decimalsLimit: 2, emoji: "🇨🇦"},
  "AUD": {"title": "호주", "symbol": "$", decimalsLimit: 2, emoji: "🇦🇺"},
  "CNY": {"title": "중국", "symbol": "¥", decimalsLimit: 2, emoji: "🇨🇳"},
  "NZD": {"title": "뉴질랜드", "symbol": "$", decimalsLimit: 2, emoji: "🇳🇿"},
  "SGD": {"title": "싱가포르", "symbol": "$", decimalsLimit: 2, emoji: "🇸🇬"},
}

export const getCurrencyString = (currencyKey) => {
  return `${currencyTypes[currencyKey].emoji} ${currencyTypes[currencyKey].title} (${currencyTypes[currencyKey].symbol})`
}

export const convertToKoreanNumber = (number) => {
  let inputNumber  = number <= 0 ? 0 : number;
  let unitWords    = ['', '만', '억', '조', '경'];
  let splitUnit    = 10000;
  let splitCount   = unitWords.length;
  let resultArray  = [];
  let resultString = '';

  for (let i = 0; i < splitCount; i++){
    let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0){
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i++){
    if(!resultArray[i]) continue;
    resultString = " " + String(resultArray[i]) + unitWords[i] + resultString;
  }
  resultString = resultString.slice(1);
  if(number <= 0) resultString = 0;
  return `${resultString}원`;
}

export const formatCurrency = (num, toFixed = 2, currency="￦") => {
  num = parseFloat(num);
  return currency + num.toFixed(toFixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const getCurrencyExchangeRate = (currency, date) => {
  currency = currency.toLowerCase();
  if(!date){date = "latest";}
  return new Promise( (resolve, reject) => {
    axios.get(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@${date}/v1/currencies/${currency}.json`).then((res) => {
      resolve(res);
    })
  });
}

export const getAssetCurrencyExchangeRates = (assets, date) => {
  assets.map( (asset) => {
    return getCurrencyExchangeRate(asset.currency);
  })
}
