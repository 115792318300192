import {isValidEmail} from "../../helper/validationHelper";
import {useState} from "react";
import {subscribe} from "../../helper/subscribeHelper";
import Loader from "./Loader";
import "./SubscribeForm.scss";
import AnimatedLogo from "./AnimatedLogo";

const SubscribeForm = ({callbackFunc, pageName}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSubscribeButtonClick = () => {
    setLoading(true);
    subscribe(email, pageName).then( () => {
      if(callbackFunc)
        callbackFunc();
      setLoading(false);
      setMessage({type: "positive", text: "구독해주셔서 감사합니다 😊"});
      setEmail("");
    }).catch((e) => {
      setLoading(false);
      setMessage({type: "negative", text: "앗, 문제가 생겼어요. 나중에 다시 시도해주시겠어요? 🥲"})
    });
  }

  return (
    <div className={"subscribe-form"}>
      {loading ? <Loader/> : ""}
      <a className={"logo-title-wrapper"} href={"https://sailingcapitalism.com"} target="_blank">
        <AnimatedLogo bgColor={"bg250"}/>
        <span className={'brand-title'}>자본주의 항해</span>
      </a>

      <span>매주 한 번, 주식 투자 공부에 도움되는 글과 도구 소식을 받아보세요!</span>

      {!message ? (
        <>
          <input className={'input-email'} type={"email"}
                 placeholder={"이메일을 입력해주세요"}
                 onChange={(e) => {setEmail(e.target.value)}}
                 value={email} disabled={loading}/>
          <button className={"btn-fill c-sailing"} disabled={!email || !isValidEmail(email) || loading || message}
                  onClick={() => {onSubscribeButtonClick()}}>자본주의 항해 뉴스레터 구독하기</button>
        </>
      ) : ""}

      {message ? <div className={`message ${message.type}`}>{message.text}</div> : ""}
    </div>
  )
}

export default SubscribeForm;
