import {getRandomId} from "./generalHelper";
import {addDoc, auth, removeDoc, updateDocument} from "./firebaseHelper";
import {CURRENCY_TYPE} from "./currencyHelper";

export const getUserDocID = () => {
  return `uid_${auth.currentUser.uid}`;
}

export const initialSettings = {
  mainCurrency: CURRENCY_TYPE.KRW
}

export const createSettings = () => {
  return new Promise( (resolve) => {
    let docId = getUserDocID();
    addDoc('settings', docId, {...initialSettings, id: docId, uid: auth.currentUser.uid}).then( () => {
      resolve();
    })
  })
}

export const updateSettings = (key, value) => {
  return new Promise( (resolve) => {
    let data = {};
    data[key] = value;
    updateDocument('settings', getUserDocID(), data).then( () => {
      resolve();
    })
  })
}
