import "./Examination.scss";
import {useEffect, useState} from "react";
import _ from "underscore";
import Loader from "../general/component/Loader";
import {addDataToGoogleSheet} from "../helper/firebaseHelper";
import SubscribeForm from "../general/component/SubscribeForm";
import MetaTag from "../general/component/MetaTag";
import {copyText} from "../helper/generalHelper";
import kakaoImage from "../assets/img/kakao-talk-logo.png";
import AnimatedLogo from "../general/component/AnimatedLogo";
import FeedbackForm from "../general/component/FeedbackForm";
import {Link} from "react-router-dom";
import {appScriptUrls} from "../helper/appScripts";
import {setKakaoShareButton} from "../helper/kakaoShareHelper";
import ThemeChangeButton from "../general/component/ThemeChangeButton";

const statusType = {
  INTRO: "INTRO",
  TEST: "TEST",
  ANALYSING: "ANALYSING",
  RESULT: "RESULT"
}

const Examination = ({data, onThemeButtonClick, children}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [status, setStatus] = useState(statusType.INTRO);
  const [result, setResult] = useState(null);
  const [urlCopied, setUrlCopied] = useState(false);

  useEffect(() => {
    if(status === statusType.RESULT) {setKakaoShareButton(data.kakaoShareData)};
  }, [status]);

  const moveQuestion = (direction) => {
    if (direction === "next")
      setCurrentIndex(currentIndex + 1);
    else
      setCurrentIndex(currentIndex - 1);
  }

  const collectAnswer = (questionIndex, types) => {
    let newAnswers = _.clone(answers);
    newAnswers[`question${questionIndex}`] = types
    setAnswers(newAnswers);

    if (data.questions.length - 1 === questionIndex) {
      setStatus(statusType.ANALYSING);
      getFinalResult();

      setTimeout(() => {
        setStatus(statusType.RESULT);
      }, 2000)
    } else {
      moveQuestion("next");
    }
  }

  const getFinalResult = () => {
    let typeKeys = _.keys(data.types);
    let countByTypes = {};

    typeKeys.map( (key, index) => {
      countByTypes[key] = 0;
    });

    let totalCount = 0;
    _.keys(answers).map((questionIndexKey, index) => {
      let answerTypes = answers[questionIndexKey];
      answerTypes.map((answerType, idx) => {
        let type = answerType.type;
        if (!countByTypes[type])
          countByTypes[type] = 1
        else
          countByTypes[type]++;
        totalCount++;
      });
    });

    let finalType = "";
    _.keys(countByTypes).map((type, index) => {
      if (index === 0)
        finalType = type;

      if (countByTypes[type] > countByTypes[finalType])
        finalType = type;
    });

    let finalData = {
      sheetName : data.appScriptSheetName,
      finalType,
      countByTypes,
      totalCount
    }
    setResult(finalData);

    addDataToGoogleSheet(appScriptUrls.examination, finalData);
  }

  const copyUrl = () => {
    copyText(window.location.href).then(() => {
      setUrlCopied(true);
    });
  }

  return (
    <div className={'examination'}>
      <MetaTag title={data.title} description={data.description} image={data.coverImage}/>
      <div className={'boundary'}>
        <div className={'fixed-header'}>

          <Link className={'link-to-blog'} to={"/"}>
            <AnimatedLogo bgColor={"bg100"}/>
            <div className={"text"}>
              <span>자본주의 항해 도구</span>
              <span>투자 공부를 위한 도구들</span>
            </div>
          </Link>
          <ThemeChangeButton onThemeButtonClick={onThemeButtonClick}/>
        </div>

        {status === statusType.INTRO ? (
          <div className={"page-test intro"}>
            <div className={"test-body"}>
              <h1>{data.title}</h1>
              <img src={data.coverImage} alt=""/>
              <p>{data.intro}</p>
            </div>
            <button className={'btn-start btn-fill c-sailing'} onClick={ () => {setStatus(statusType.TEST)}}>시작하기!</button>
          </div>
        ) : ""}

        {status === statusType.TEST ? (
          <div className={"page-test"}>
            <h5 className={"title"}>{data.title}</h5>
            <div className={"section-slider"}>
              <div className={'slider'}>
                <div className={'progress-bar'} style={{width: `${(currentIndex / (data.questions.length))*100}%`}}></div>
                <div className={'line'}/>
                {data.questions.map( (question, index) => <div className={'line'} key={index}/>)}
              </div>
              <span className={"page-number"}>{currentIndex}/{data.questions.length}</span>
            </div>

            <div className={'test-body'}>
              <div className={"question-container"}
                   style={{
                     transform: `translateX(${currentIndex * -100}%)`}}>
                {data.questions.map( (question, index) => {
                  return (
                    <div className="question-item" key={index}>
                      <h1 className={'question'}>{question.question}</h1>
                      <div className={'answers'}>
                        {question.answers.map( (answer, i) => {
                          return (
                            <button className={`btn-answer btn-fill ${answers[`question${index}`] === answer.types ? 'c-sailing' : 'c-el1'}`} key={i}
                                    onClick={ () => {collectAnswer(index, answer.types)}}>
                              {answer.label}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={'test-footer'}>
              <button className={'btn-fill c-el1 s-pill'} onClick={ () => {moveQuestion("prev")}} disabled={currentIndex === 0}>이전</button>
              <button className={'btn-fill c-el1 s-pill'} onClick={ () => {moveQuestion("next")}} disabled={!answers[`question${currentIndex}`]}>다음</button>
            </div>
          </div>
        ) : ""}

        {status === statusType.ANALYSING ? (
          <div className={"page-analysing"}>
            <Loader/>
            <h3>🔍 성향 분석중...</h3>
          </div>
        ) : ""}

        {status === statusType.RESULT ? (
          <div className={"test-result"}>
            <div className={'result-header'}>
              <h4 className={"title-label"}>테스트 결과</h4>
              <span className={"type-label"} style={{backgroundColor: data.types[result.finalType].color}}>"{data.types[result.finalType].title}" 타입</span>
              <h1 className={"result-label"}>{data.types[result.finalType].description.nickName}!</h1>

              <p className={'summary'}>{data.types[result.finalType].description.summary}</p>
            </div>

            <div className={'result-body'}>
              <div className={'detail'}>
                <h4 className={"detail-title"}>세부 결과</h4>
                <div className={"graph"}>
                  {_.keys(data.types).map( (type, index) => {
                    if(_.keys(result.countByTypes).includes(type)){
                      let ratio = (result.countByTypes[type]/result.totalCount * 100).toFixed(1);
                      return (<div className={"bar"} key={index} style={{width: `${ratio}%`, backgroundColor: data.types[type].color}}/>)
                    }
                  })}
                </div>
                <div className={"percentage"}>
                  {_.keys(data.types).map( (type, index) => {
                    if(_.keys(result.countByTypes).includes(type)){
                      let ratio = (result.countByTypes[type]/result.totalCount * 100).toFixed(1);
                      return (
                        <div className={"type-item text"} key={index}>
                          <div className={'color-ball'} style={{backgroundColor: data.types[type].color}}/>
                          <span className={"type"}>{data.types[type].title}</span>
                          <span className={"sub"}>{result.countByTypes[type]}/{result.totalCount}점</span>
                          <span>{ratio}%</span>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
              <div className={"description"}>
                <h4>"{data.types[result.finalType].description.slogan}"</h4>
                <p>{data.types[result.finalType].description.body}</p>
              </div>
            </div>
            <div className={"result-footer"}>
              {children}
              <button className={'btn-fill c-el1'} onClick={ () => {copyUrl()}}>테스트 링크 복사{urlCopied && (<span>(복사됨)</span>)}</button>
              <button id='kakaotalk-sharing-btn' className={'btn-fill'}>
                <img src={kakaoImage} alt=""/><span>카톡으로 테스트 공유하기!</span>
              </button>
              <SubscribeForm pageName={"investorTypeTest"}/>
            </div>
            <FeedbackForm featureType={"investorType"} appScriptUrl={appScriptUrls.toolFeedback}/>
          </div>
        ) : ""}

      </div>

    </div>
  )
}

export default Examination;



