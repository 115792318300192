import "./FeedbackFrom.scss";
import {useState} from "react";
import {addDataToGoogleSheet} from "../../helper/firebaseHelper";
import Loader from "./Loader";
import {isValidEmail} from "../../helper/validationHelper";

const FeedbackForm = ({featureType, appScriptUrl}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendFeedback = (e) => {
    e.preventDefault();
    setIsLoading(true);
    addDataToGoogleSheet(appScriptUrl, {featureType, feedback, email});
    setTimeout( () => {
      setIsSent(true);
      setIsLoading(false);
    }, 1000);
  }

  const resetForm = () => {
    setFeedback("");
    setEmail("");
    setDisplayForm(false);
    setIsSent(false);
  }

  return (
    <div className={'feedback-form'}>
      {isLoading ? <Loader/> : ""}
      {displayForm ? "" : (<button className='btn-display-form btn-fill s-pill c-sailing' onClick={() => {setDisplayForm(true)}}>피드백</button>)}

      <div className={`form ${displayForm ? '' : 'hide'}`}>
        {isSent ? (
          <>
            <h4 className={'thank-message'}>소중한 의견 감사합니다 🙇🏻‍♂️</h4>
            <button className={'btn-fill c-el3'} onClick={() => {resetForm()}}>닫기</button>
          </>
        ) : (
          <>
            <div className={'form-header'}>
              <p>피드백을 주세요!</p>
            </div>
            <textarea name="feedback-body" id="" cols="30" rows="10" onChange={(e) => {setFeedback(e.target.value)}} placeholder={"칭찬, 혹은 개선할 점이 있다면 의견 부탁드려요!"} value={feedback}></textarea>
            <input type="email" placeholder={"회신을 원하시면 이메일을 남겨주세요!"} value={email} onChange={(e) => {setEmail(e.target.value)}}/>
            <div className={'form-footer'}>
              <button className={'btn-fill c-el3'} onClick={() => {resetForm()}}>닫기</button>
              <button className={'btn-fill c-sailing'} onClick={sendFeedback} disabled={!feedback || (email && !isValidEmail(email))}>의견 전달하기!</button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default FeedbackForm;
