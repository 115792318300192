import Lottie from "lottie-react";
import sailingAnimation from "../../assets/lottie/Sailing.json";
import "./AnimatedLogo.scss";

const AnimatedLogo = ({bgColor="bg0"}) => {
  return (
    <Lottie className={`animated-logo ${bgColor}`} animationData={sailingAnimation} loop={true}/>
  )
}

export default AnimatedLogo;
