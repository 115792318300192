
export const investorTypeKakaoShareData = {
  title: '나의 투자 성향을 테스트해보세요!',
  description: '방어/공격형, 나의 성향 비율은 어떻게 될까요? 👀',
  thumbnailImage: "https://ifh.cc/g/xmBAvl.jpg",
  buttonText: "테스트해보기!",
  link: "https://tools.sailingcapitalism.com/examination/investor-type"
}

export const investorType = {
  VERY_CONSERVATIVE: {
    type: "VERY_CONSERVATIVE", title:"매우 보수적", color: "rgb(31,116,201)",
    description: {
      nickName: "투자의 거북이",
      summary: "안전을 최우선으로 하며 잠을 편히 잘 수 있는 투자를 선호합니다",
      body: "배당주 중심의 투자, 고정된 수익을 제공하는 우량주에 투자하여 장기적인 자산 성장을 목표로 합니다. 시장의 변동성에 크게 흔들리지 않는 안전한 항구를 찾습니다",
      slogan: "천천히 가도 꾸준히 가면 도착한다"
    }
  },
  CONSERVATIVE: {
    type: "CONSERVATIVE", title: "보수적", color: "rgb(45,152,161)",
    description: {
      nickName: "투자의 마라토너",
      summary: "리스크는 낮게, 안정성은 높게 유지하며 조금씩 앞으로 나아가고 싶어합니다",
      body: "혼합 자산 배분을 통해 일부는 안정적인 주식에, 일부는 채권이나 현금과 같은 더 안전한 자산에 투자합니다. 중소형주보다는 대형주에 더 많이 투자하는 경향이 있습니다",
      slogan: "안전 운전으로 목적지에 도달한다"
    }
  },
  NEUTRAL: {
    type: "NEUTRAL", title:"중립", color: "rgb(137,66,199)",
    description: {
      nickName: "투자의 저울",
      summary: "리스크와 보상 사이의 완벽한 균형을 찾으려고 합니다",
      body: "다양한 종류의 주식에 분산 투자하여 리스크를 관리하면서도 적당한 성장을 추구합니다. 성장주와 가치주, 그리고 시장의 다양한 섹터에 걸쳐 포트폴리오를 구성합니다",
      slogan: "적절한 균형이 최고의 안정성이다"
    }
  },
  AGGRESSIVE: {
    type: "AGGRESSIVE", title:"공격적", color: "rgb(210,123,41)",
    description: {
      nickName: "투자의 모험가",
      summary: "높은 수익을 위해 높은 리스크를 감수할 준비가 되어 있습니다",
      body: "성장주 투자에 집중하며, 신기술이나 신흥 시장에 대한 투자를 선호합니다. 주가 변동성이 큰 종목에서 빠른 수익을 찾으려고 합니다",
      slogan: "높은 파도가 높은 보상을 가져온다"
    }
  },
  VERY_AGGRESSIVE: {
    type: "VERY_AGGRESSIVE", title:"매우 공격적", color: "rgb(201,65,65)",
    description: {
      nickName: "투자의 스턴트맨",
      summary: "극도의 리스크를 무릅쓰고 매우 높은 수익을 목표로 합니다",
      body: "레버리지를 사용한 투자, 고위험 고수익 주식, 옵션 거래나 선물 거래 등 고도의 투자 기술을 요구하는 시장에서 활약합니다. 시장의 작은 움직임에서 큰 수익을 창출하려고 시도합니다",
      slogan: "하늘을 나는 것이 두렵지 않다면, 날개를 펼쳐라"
    }
  }
}

const {VERY_CONSERVATIVE, CONSERVATIVE, NEUTRAL, AGGRESSIVE, VERY_AGGRESSIVE} = investorType;

export const investorTypeQuestions = [
  {
    question: "지금까지 나는 어떤 투자자였나? 🧐",
    answers: [
      {label: "초보자!", types: [VERY_CONSERVATIVE, CONSERVATIVE]},
      {label: "경험이 조금 있음!", types: [NEUTRAL]},
      {label: "경험이 많음!", types: [AGGRESSIVE]},
      {label: "전문가 수준임!", types: [VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "투자, 나는 얼마나 길게 보고 하나? 👀",
    answers: [
      {label: "1년 미만! 짧게하고 빠져나와야지!", types: [VERY_CONSERVATIVE]},
      {label: "1 ~ 3 년정도는 해야지!", types: [CONSERVATIVE]},
      {label: "3 ~ 5년 더 길게 봐야지!", types: [NEUTRAL]},
      {label: "5년 이상 장기 우상향 노린다!", types: [AGGRESSIVE, VERY_AGGRESSIVE]},
    ]
  },
  {
    question: "나의 투자 목표는 뭐다? 🎯",
    answers: [
      {label: "잃지만 않으면 돼!", types: [VERY_CONSERVATIVE]},
      {label: "정기적으로 현금 흐름을 만들고싶어!", types: [CONSERVATIVE]},
      {label: "내 자산을 더 키우고싶어!", types: [NEUTRAL]},
      {label: "내 자산을 엄청나게 키우고싶어!", types: [AGGRESSIVE, VERY_AGGRESSIVE]},
    ]
  },
  {
    question: "투자에서 손실을 볼 수도 있다고 한다, 나의 반응은? 💸",
    answers: [
      {label: "절대 못잃지! 감수 못함!", types: [VERY_CONSERVATIVE]},
      {label: "작은 손실이라면 뭐.. 감수할 수 있음", types: [CONSERVATIVE]},
      {label: "중간 정도까지는 감수 가능!", types: [NEUTRAL]},
      {label: "장기적 수익을 위해서는 큰 손실도 기꺼이!", types: [AGGRESSIVE, VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "시장이 하락할 조짐이 보인다! 나의 반응은? 📉",
    answers: [
      {label: "즉시 판매하여 손실 최소화!", types: [VERY_CONSERVATIVE]},
      {label: "조금 더 기다려 보자, 일단 상황을 관찰!", types: [CONSERVATIVE]},
      {label: "추가 투자도 고려하면서 이익의 기회를 엿본다!", types: [AGGRESSIVE]},
      {label: "하락은 큰 기회라 했다, 대폭적 투자 준비!", types: [VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "내가 가장 많이 선호하는, 혹은 보유한 투자 자산은? 🤩",
    answers: [
      {label: "예/적금 및 채권", types: [VERY_CONSERVATIVE, CONSERVATIVE]},
      {label: "다양한 유형의 자산에 분산 투자", types: [NEUTRAL]},
      {label: "주식, 펀드", types: [NEUTRAL, AGGRESSIVE]},
      {label: "파생 상품, 고위험 금융 상품", types: [VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "나의 목표 연 수익률은? 🎯",
    answers: [
      {label: "2%, 절대 원금 사수!", types: [VERY_CONSERVATIVE]},
      {label: "2 ~ 5%, 물가 상승률 정도는 돼야지! ", types: [CONSERVATIVE]},
      {label: "5 ~ 10%, 시장 평균은 가야지!", types: [NEUTRAL]},
      {label: "10% 이상은 해야지!", types: [AGGRESSIVE, VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "1억을 투자 했는데 10%인 1,000만원을 잃었다. 나의 반응은? 😨",
    answers: [
      {label: "헉, 즉시 모든 투자를 청산!", types: [VERY_CONSERVATIVE]},
      {label: "일단 손실 부분만 매도!", types: [CONSERVATIVE]},
      {label: "당장 원인 파악, 상황을 지켜보며 기다림", types: [NEUTRAL]},
      {label: "더 나아가, 추가 투자로 손실 회복 계획", types: [AGGRESSIVE, VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "투자, 나에겐 이게 가장 중요하다! ⭐️",
    answers: [
      {label: "안정성과 예측 가능성", types: [VERY_CONSERVATIVE]},
      {label: "꾸준한 성장!", types: [CONSERVATIVE]},
      {label: "변동성이 좀 있어도 높은 성장률!", types: [NEUTRAL]},
      {label: "변동성이 상당해도 매우 높은 성장률!", types: [AGGRESSIVE, VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "경제가 불안하다, 나의 투자 전략 변경은 어떻게? 🫨",
    answers: [
      {label: "빠르게 더 안전한 자산으로 이동!", types: [VERY_CONSERVATIVE, CONSERVATIVE]},
      {label: "흔들리지 말자, 일단 원래 계획을 유지", types: [NEUTRAL]},
      {label: "이건 기회다, 더 공격적으로 투자 계획!", types: [AGGRESSIVE, VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "투자 할 때 내가 주로 참고하는, 혹은 하고싶은 정보는? 📊",
    answers: [
      {label: "경제 상황, 시장 동향과 금리 변동!", types: [CONSERVATIVE, NEUTRAL]},
      {label: "기업 실적과 산업 분석!", types: [AGGRESSIVE]},
      {label: "주가의 움직임과 시장 심리!", types: [VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "새로운 투자 기회를 발견! 이를 평가할 때 내게 가장 중요한 요소는? 🔍",
    answers: [
      {label: "안정성과 보수적인 수익률", types: [VERY_CONSERVATIVE, CONSERVATIVE, NEUTRAL]},
      {label: "고수익률과 성장 가능성", types: [NEUTRAL, AGGRESSIVE, VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "투자 손실이 발생했다! 나의 반응은? 📉",
    answers: [
      {label: "당장 투자를 재평가! 필요한 조치 취함!", types: [CONSERVATIVE, NEUTRAL]},
      {label: "결국 우상향한다! 손실을 감수하고 버티기", types: [AGGRESSIVE, VERY_AGGRESSIVE]}
    ]
  },
  {
    question: "나의 금융 뉴스, 시장 분석 주기는? 🗞️",
    answers: [
      {label: "매일!", types: [AGGRESSIVE, VERY_AGGRESSIVE]},
      {label: "나름 주기적으로!", types: [NEUTRAL, CONSERVATIVE]},
      {label: "가끔 확인!", types: [VERY_CONSERVATIVE]},
    ]
  },
  {
    question: "새로운 투자 기술이나 전략을 배우는 것에 대한 나의 태도는? 📚",
    answers: [
      {label: "적극적 학습! 적용에 관심이 많음", types: [AGGRESSIVE, VERY_AGGRESSIVE]},
      {label: "필요하다고 느낄 때만 학습!", types: [NEUTRAL, CONSERVATIVE, VERY_CONSERVATIVE]},
    ]
  }
]

export const investorTypeTestData = {
  title: "나의 투자 성향 알아보기!",
  description: "투자 성향, 나는 어떤 스타일일지 테스트해봐요!",
  intro: <span>투자, 나의 방어/공격형 비율은!? <br/>내 투자 성향을 알아봐요!</span>,
  coverImage : require("../../assets/img/investor_type_cover_optimized.jpg"),
  kakaoShareData : investorTypeKakaoShareData,
  types: investorType,
  questions : investorTypeQuestions,
  appScriptSheetName: "investorType"
}






