import "./ThemeChangeButton.scss";

const ThemeChangeButton = ({onThemeButtonClick, isFixed=false}) => {
  return (
    <button className={`btn-fill s-pill c-el1 btn-change-theme ${isFixed ? 'fixed' : ''}`}
            onClick={() => {onThemeButtonClick();}}>🌓</button>
  );
}

export default ThemeChangeButton;
