import "./AssetTracking.scss";
import AssetTrackingTable from "./component/AssetTrackingTable";
// import {useState, useEffect} from "react";
import {useEffect, useState} from "react";
import {auth, db} from "../helper/firebaseHelper";
import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {getCurrencyExchangeRate} from "../helper/currencyHelper";
import _ from "underscore";
import RecentDashboard from "./component/RecentDashboard";
import NeedVerification from "../general/component/NeedVerification";

const AssetTracking = ({userSettings, }) => {
  const [assets, setAssets] = useState([]);
  const [recordSets, setRecordSets] = useState([]);
  const [assetToMainCurrencyExchangeRates, setAssetToMainCurrencyExchangeRates] = useState({});
  const [displayDashboard, setDisplayDashboard] = useState(false);

  useEffect(() => {
    if(!auth.currentUser.emailVerified) return;
    let uid = auth.currentUser.uid;
    const assetQuery = query(collection(db, "asset"), where("uid", "==", uid));

    //Get Assets
    getCurrencyExchangeRate(userSettings.mainCurrency).then( (res) => {
      let mainCurrencyExchangeRate = res.data[userSettings.mainCurrency.toLowerCase()];
      // initTmpAsset();
      onSnapshot(assetQuery, (querySnapshot) => {
        let assetArray = [];
        let assetToMainCurrencyExchangeRateObj = {};

        querySnapshot.forEach((doc) => {
          let docData = doc.data();
          if(docData.currency !== userSettings.mainCurrency){
            assetToMainCurrencyExchangeRateObj[docData.currency] = 1/mainCurrencyExchangeRate[docData.currency.toLowerCase()];
          }
          assetArray.push(docData);
        });
        // initTmpRecord(assetArray);
        setAssetToMainCurrencyExchangeRates(assetToMainCurrencyExchangeRateObj);
        setAssets(assetArray);
      });
    })
  }, [userSettings]);

  useEffect(() => {
    if(!auth.currentUser.emailVerified) return;
    //Get Records
    const recordQuery = query(collection(db, "assetRecord"), where("uid", "==", auth.currentUser.uid), orderBy("date", "desc"));
    onSnapshot(recordQuery, (querySnapshot) => {
      let recordArray = [];
      querySnapshot.forEach((doc) => {
        let recordSet = doc.data();
        recordSet['summary'] = getSummary(recordSet);
        recordArray.push(recordSet);
      });

      setRecordSets(recordArray);
    });
  }, [assets]);

  const getValueToAdd = (recordSet, targetAsset, ) => {
    let recordAssetValue = recordSet.assets[targetAsset.id].value;
    if(targetAsset.currency !== userSettings.mainCurrency){ //mainCurrency로 환전된 금액 더해줘야함
      return recordAssetValue * assetToMainCurrencyExchangeRates[targetAsset.currency];
    }else{
      return recordAssetValue;
    }
  }

  const getSummary = (recordSet) => {
    let summary = {
      total: 0,
      byAsset: {},
      byCurrent: {},
      byCurrency: {}
    }

    _.keys(recordSet.assets).map( (recordAssetId) => {
      let asset = assets.find( (asset) => asset.id === recordAssetId);
      if(asset){
        let assetValue = getValueToAdd(recordSet, asset);
        summary.total += assetValue;

        if(!summary.byAsset[asset.type]) summary.byAsset[asset.type] = 0;
        summary.byAsset[asset.type] += assetValue;

        if(!summary.byCurrent[asset.currentType]) summary.byCurrent[asset.currentType] = 0;
        summary.byCurrent[asset.currentType] += assetValue;

        if(!summary.byCurrency[asset.currency]) summary.byCurrency[asset.currency] = 0;
        summary.byCurrency[asset.currency] += assetValue;
      }
    });
    return summary;
  }

  return (
    <div className={'asset-tracking page'}>
      {auth.currentUser.emailVerified ? (
        <>
          <AssetTrackingTable assets={assets} recordSets={recordSets} userSettings={userSettings} assetToMainCurrencyExchangeRates={assetToMainCurrencyExchangeRates} setDisplayDashboard={setDisplayDashboard}/>
          <div className={`recent-dashboard-layer ${displayDashboard ? 'show' : 'hide'}`}>
            <RecentDashboard assets={assets} recentRecordSet={recordSets[0]} recordSets={recordSets} userSettings={userSettings} assetToMainCurrencyExchangeRates={assetToMainCurrencyExchangeRates} setDisplayDashboard={setDisplayDashboard}/>
          </div>
        </>
      ) : (
        <NeedVerification user={auth.currentUser}/>
      )}
    </div>
  )
}

export default AssetTracking;
