

const Select = ({value, onChange, children}) => {
  return (
    <div className={'select-element'}>
      <select name="" id="" value={value} onChange={onChange}>
        {children}
      </select>
      <i className="fa-solid fa-caret-down icon-dropdown"/>
    </div>
  )
}

export default Select;
