import {Helmet} from "react-helmet";

const MetaTag = ({title, description, image}) => {
  let originUrl = window.location.origin;
  let currentUrl = window.location.href;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name={"description"} content={description}/>
      <meta name={"author"} content={"시몬"}/>

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${originUrl}${image}`} />
      <meta property="og:url" content={currentUrl} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${originUrl}${image}`} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default MetaTag
