import "./NoMatch.scss";
import {Link} from "react-router-dom";

const NoMatch = () => {
  return (
    <div className={'no-match'}>
      <div className={"content"}>
        <h1>4😮4</h1>
        <h2>앗, 페이지를 찾을 수 없어요</h2>
        <Link className={'btn-fill c-sailing'} to={"/"}>홈으로 이동하기!</Link>
      </div>
    </div>
  )
}

export default NoMatch;
