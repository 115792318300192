import {logout} from "../../helper/firebaseHelper";
import "./NavBar.scss";
import {Link} from "react-router-dom";
import AnimatedLogo from "./AnimatedLogo";
import {DropdownMenu, DropdownMenuItem} from "./DropdownMenu";
import {useNavigate, useLocation} from "react-router-dom/dist";
import {useEffect, useState} from "react";

const menus = [
  {
    icon: "fa-solid fa-calculator",
    label: "경제적 자유 계산기",
    url: "plan"
  },
  {
    icon: "fa-solid fa-coins",
    label: "자산 현황",
    url: ""
  },
  {
    icon: "fa-solid fa-bars",
    label: "더보기",
    url: "more"
  },
]

const NavBar = ({user, theme, onThemeButtonClick}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentMenuUrl, setCurrentMenuUrl] = useState("");

  useEffect(() => {
    let rootUrl = location.pathname.split("/")[1];
    setCurrentMenuUrl(rootUrl);
  }, [location])

  const navigateTo = (toLink) => {
    navigate(toLink);
  }
  return (
    <div className={'nav-bar'}>
      <div className={'boundary'}>
        <Link className={'btn-home'} to={""}>
          <AnimatedLogo bgColor={'bg50'}/>
          <h1 className={'title'}>자본주의 항해</h1>
        </Link>

        <div className={'menus hide-on-mobile'}>
          {menus.map( (menu, index) => (
            <Link className={`btn-fill s-pill c-none ${menu.url} ${currentMenuUrl === menu.url ? 'active' : ''}`} to={menu.url} key={index}>
              <i className={menu.icon}/> {menu.label && <span>{menu.label}</span>}
            </Link>
          ))}
        </div>

        {/*<div className={'right-buttons'}>*/}
        {/*  {!user && <Link to={"/auth"}>로그인</Link>}*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default NavBar;
