export const setKakaoShareButton = (kakaoShareData) => {
  if (window.Kakao) {
    const kakao = window.Kakao;
    let {title, description, thumbnailImage, buttonText, link} = kakaoShareData;

    kakao.Share.createDefaultButton({
      container: '#kakaotalk-sharing-btn',
      objectType: 'feed',
      content: {
        title: title,
        description: description,
        imageUrl: thumbnailImage,
        link: {
          mobileWebUrl: link,
          webUrl: link,
        },
      },
      buttons: [
        {
          title: buttonText,
          link: {
            mobileWebUrl: link,
            webUrl: link,
          },
        }
      ],
    });
  }
}
