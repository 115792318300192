import "./RecentDashboard.scss";
import {useEffect, useState} from "react";
import {getRandomColor} from "../../helper/generalHelper";
import _ from "underscore";
import {formatCurrency, currencyTypes} from "../../helper/currencyHelper";
import strings from "../../general/strings";
import {assetTypes} from "../../helper/assetTrackingHelper";
import {currentTypes} from "../../helper/assetTrackingHelper";

const RecentDashboard = ({assets, recentRecordSet ,recordSets, userSettings, assetToMainCurrencyExchangeRates, setDisplayDashboard}) => {
  const [recentAssets, setRecentAssets] = useState([]);

  useEffect(() => {
    if(!recentRecordSet || !assets) return;
    let items = [];
    _.keys(recentRecordSet.assets).map( (recordAssetId, idx) => {
      let assetValue = recentRecordSet.assets[recordAssetId].value;
      if(!assetValue) return null;

      let asset = assets.find( (ast) => ast.id === recordAssetId);
      if(!asset) return null;
      if(asset.currency !== userSettings.mainCurrency){
        assetValue = assetValue * assetToMainCurrencyExchangeRates[asset.currency];
      }
      items.push({
        name: asset.name,
        value: assetValue,
        color: getRandomColor()
      })
    })
    setRecentAssets(items);

  }, [recordSets])

  const renderGraphAndLegend = (title, items, totalValue) => {
    items = items.sort( (a, b) => b.value - a.value);
    let mainCurrencyFormatInfo = currencyTypes[userSettings.mainCurrency];

    return (
      <div className={'dashboard-item'} key={title}>
        <div className={'dashboard-item-header'}>
          <h4>{title}</h4>
        </div>
        <div className={'dashboard-item-body'}>
          <div className={'bar-graph'}>
            {
              items.map( (item, idx) => {
                let {value, color} = item;
                return <div className={'bar-item'} style={{width: `${value/totalValue * 100}%`, backgroundColor: `#${color}`}} key={idx}/>
              })
            }
          </div>
          <div className={'legend'}>
            {
              items.map( (item, idx) => {
                let {name, value, color} = item;
                return (
                  <div className={'legend-item'} style={{width: `${value/totalValue * 100}%`}} key={idx}>
                    <div className={'color'} style={{backgroundColor: `#${color}`}}/>
                    <div className={'main'}>
                      <div className={'name'}>{name}</div>
                      <div className={'value'}>{formatCurrency(value, mainCurrencyFormatInfo.decimalsLimit, mainCurrencyFormatInfo.symbol)}</div>
                    </div>
                    <div className={'ratio'}>{`${(value/totalValue * 100).toFixed(2)}%`}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  const renderByCurrency = (totalValue) => {
    // console.log(recentRecordSet.summary);
    let summaryKeyTypes = {
      byAsset: assetTypes,
      byCurrent: currentTypes,
      byCurrency: currencyTypes
    }
    return (
      <>
        {_.keys(recentRecordSet.summary).map( (byType, index) => {
          let typeSummary = recentRecordSet.summary[byType];
          if(byType === "total") return null;
          let items = [];
          _.keys(typeSummary).map( (detailTypeKey, index) => {
            let detailTypeValue = typeSummary[detailTypeKey];
            let typeInfo = summaryKeyTypes[byType][detailTypeKey];
            items.push({
              name: `${typeInfo.emoji} ${typeInfo.title}`,
              value: detailTypeValue,
              color: getRandomColor()
            })
          })
          return (renderGraphAndLegend(strings[byType], items, totalValue))
        })}
      </>
    )
  }

  const renderDashboard = () => {
    let totalValue = recentRecordSet.summary.total;
    return (
      <>
        {renderGraphAndLegend("개별자산비중", recentAssets, totalValue)}
        {renderByCurrency(totalValue)}
      </>
    )
  }

  return (
    <div className={'recent-dashboard'}>
      <div className={'dashboard-header'}>
        <div className={'container-title'}>
          <h2 className={'page-title'}>최근 자산 현황</h2>
          <button className={'btn-close btn-fill c-none'} onClick={() => {setDisplayDashboard(false)}}>닫기</button>
        </div>
        <span>{recentRecordSet ? recentRecordSet.date : null}</span>
      </div>
      <div className={'dashboard-body'}>
        {(!assets.length || !recentRecordSet)  ? (
          <div className={'empty'}>최근 현황이 없습니다</div>
        ): (
          renderDashboard()
        )}
      </div>
    </div>
  )
}

export default RecentDashboard;
