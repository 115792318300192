import {collection, query, where, getDocs, onSnapshot} from "firebase/firestore";
import {getRandomId} from "./generalHelper";
import {addDoc, auth, db, removeDoc, updateDocument} from "./firebaseHelper";
import _ from "underscore";

export const ASSET_TYPE = {
  CASH: 'CASH',
  STOCK: "STOCK",
  DEPOSIT: "DEPOSIT",
  INSTALLMENT: "INSTALLMENT",
  BOND: "BOND",
  ETF: "ETF",
  REAL_ESTATE: "REAL_ESTATE",
  INSURANCE: "INSURANCE",
  DEBT : "DEBT",
  CRYPTO_CURRENCY: "CRYPTO_CURRENCY",
  GOODS : "GOODS",
  ETC: "ETC"
}

export const assetTypes = {
  CASH: {title: "현금", emoji: "💵", isFloatingAsset: false},
  STOCK: {title: "주식", emoji: "🏢", isFloatingAsset: true},
  DEPOSIT: {title: "예금", emoji: "🏦", isFloatingAsset: false},
  INSTALLMENT: {title: "적금", emoji: "🏦", isFloatingAsset: false},
  BOND: {title: "채권", emoji: "💵", isFloatingAsset: true},
  ETF: {title: "ETF", emoji: "📈", isFloatingAsset: true},
  REAL_ESTATE: {title: "부동산", emoji: "🏘️", isFloatingAsset: true},
  INSURANCE: {title: "보험", emoji: "⛑️", isFloatingAsset: false},
  DEBT : {title: "부채", emoji: "💳", isFloatingAsset: false},
  CRYPTO_CURRENCY: {title: "암호화폐", emoji: "🪙", isFloatingAsset: true},
  GOODS : {title: "현물", emoji: "♟️", isFloatingAsset: false},
  ETC: {title: "기타", emoji: "🌏", isFloatingAsset: false},
}

export const CURRENT_TYPE = {
  CURRENT: "CURRENT",
  NON_CURRENT: "NON_CURRENT"
}

export const currentTypes = {
  CURRENT: {title: "유동", emoji: "🕊️"},
  NON_CURRENT: {title: "비유동", emoji: "🪢"}
}

export const getAssets = () => {
  return new Promise( (resolve) => {
    let uid = auth.currentUser.uid;
    const q = query(collection(db, "asset"), where("uid", "==", uid));
    const unsubs = onSnapshot(q, (querySnapshot) => {
      let assetArray = [];
      querySnapshot.forEach((doc) => {
        assetArray.push(doc.data());
      })
      resolve({assetArray});
    });
  })
}

export const addAsset = (data) => {
  return new Promise( (resolve) => {
    let docId = getRandomId();
    addDoc('asset', docId, {...data, id: docId, uid: auth.currentUser.uid}).then( () => {
      resolve();
    }).catch((error) => {
      console.log(error);
    })
  })
}

export const deleteAsset = (asset) => {
  return new Promise( (resolve) => {
    removeDoc('asset', asset.id).then( () => {
      resolve();
    })
  })
}

export const addRecord = (data) => {
  return new Promise( (resolve) => {
    let docId = getRandomId();
    _.keys(data.assets).map( (assetId, index) => {
      _.keys(data.assets[assetId]).map( (assetValueKey) => {
        let value = data.assets[assetId][assetValueKey];
        if(!value)
          value=0;
        data.assets[assetId][assetValueKey] = parseFloat(value);
      });
    });
    addDoc('assetRecord', docId, {...data, id: docId, uid: auth.currentUser.uid}).then( () => {
      resolve();
    })
  })
}

export const updateAsset = (asset, key, value) => {
  return new Promise( (resolve) => {
    let data = {};
    data[key] = value;
    updateDocument('asset', asset.id, data).then( () => {
      resolve();
    })
  })
}

export const updateRecord = (recordSet, key, value) => {
  return new Promise( (resolve) => {
    let data = {};
    data[key] = value;
    updateDocument('assetRecord', recordSet.id, data).then( () => {
      resolve();
    })
  })
}

export const removeRecord = (recordSet) => {
  return new Promise( (resolve) => {
    removeDoc('assetRecord', recordSet.id).then( () => {
      resolve();
    })
  })
}
