import {isValidEmail} from "../helper/validationHelper";
import {useEffect, useState} from "react";
import {subscribe} from "../helper/subscribeHelper";
import {sendEmailWithAttachment} from "../helper/firebaseHelper";
import Loader from "../general/component/Loader";
import "./SubscribeAndDownload.scss";

const SubscribeAndDownload = ({csvData, capData }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendResult, setSendResult] = useState("");
  const [agreeToSubscribe, setAgreeToSubscribe] = useState(true);

  const getFileTitle = () => {
    return `내 경제적 자유 계획\n(${capData.currentAge.value}~${capData.retireAge.value}세 투자로 ${capData.passAwayAge.value}세 까지).csv`
  }

  useEffect(() => {
    setSendResult("");
  }, [email])

  const sendFile = () => {
    setLoading(true);
    subscribe(email, "calculator").then( () => {
      setEmail("");
      sendEmailWithAttachment(email, getFileTitle(), csvData).then( (res) => {
        setLoading(false);
        setSendResult(res.status);
      });
    })
  }

  return (
    <div className={'subscribe-and-download'}>
      <div className={'form-wrapper'}>
        <form className={'form-subscribe-and-download'}>
          {loading ? <Loader/> : ""}
          <div className="container-guide">
            <h3>데이터를 이메일로 보내드려요!</h3>
            <p>엑셀이나 구글시트에서 활용하세요!</p>
          </div>
          <div className={"container-input"}>
            <div className={"input-wrapper"}>
              <input className={'input-email'}
                     type={"email"}
                     placeholder={"이메일을 입력해주세요"}
                     onChange={(e) => {setEmail(e.target.value)}}
                     value={email}
                     required={true}
                     disabled={loading}/>
              <button type={'submit'} className={'btn-fill c-sailing'} disabled={!agreeToSubscribe || !email || !isValidEmail(email) || loading}
                      onClick={ () => {sendFile()}}>💰 파일 받기</button>
            </div>
            <button type={"button"} className={'btn-agree btn-ghost'} onClick={() => {setAgreeToSubscribe(!agreeToSubscribe)}}>
              <input type="checkbox" checked={agreeToSubscribe} readOnly={true}/>
              <span>"자본주의 항해" 투자 공부 뉴스레터 구독에 동의합니다 😌</span>
            </button>
          </div>
        </form>
        {
          sendResult ? (
            <div className={'file-sent'}>
              <span>💸 파일이 전송됐어요! 잠시만 기다려주세요! 🙇🏻‍♂️</span>
            </div>
          ): ""
        }
      </div>
    </div>
  )
}

export default SubscribeAndDownload;
