import { initializeApp } from "firebase/app";
import {getFirestore, doc, setDoc, deleteDoc, query, where, updateDoc} from "firebase/firestore";
import {getFunctions, httpsCallable} from "firebase/functions";
import freedonPlanDownload from "../assets/emailTemplate/freedonPlanDownload";
import { getAnalytics } from "firebase/analytics";
import _ from "lodash";
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, signOut, sendPasswordResetEmail} from "firebase/auth";
import {createSettings} from "./settingsHelper";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyKvqaraT9vsjdjXbO5cugeOqzTW03LpQ",
  authDomain: "sailing-capitalism.firebaseapp.com",
  projectId: "sailing-capitalism",
  storageBucket: "sailing-capitalism.appspot.com",
  messagingSenderId: "694678809292",
  appId: "1:694678809292:web:75c8440ae1b1e474e0b069",
  measurementId: "G-CPLC4R4C3M"
};

const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const sendEmailWithAttachment = (emailAddress, fileName, csvData) => {
  return new Promise((resolve, reject) => {
    const sendEmailFunc = httpsCallable(functions, 'sendEmailWithAttachment');
    const CSVString = csvData.join("\n");
    let data = {
      emailAddress: emailAddress,
      csvFileData: CSVString,
      subject: "[자본주의 항해] 경제적 자유 계산결과 보내드려요!🚀",
      fileName: fileName,
      emailTemplate: freedonPlanDownload,
    };
    sendEmailFunc(data).then( (result) => {
      resolve({
        status: "success",
        message: ""
      })
    })
  })
}

export const addDataToGoogleSheet = (appScriptUrl, finalData) =>{
  let appScriptUrlWithParams = `${appScriptUrl.url}`;
  if(appScriptUrl.type === 'examination'){
    appScriptUrlWithParams += `?sheetName=${finalData.sheetName}&finalType=${finalData.finalType}`;
    _.keys(finalData.countByTypes).map( (typeKey, index) => {
      let value = (finalData.countByTypes[typeKey]/finalData.totalCount).toFixed(2);
      appScriptUrlWithParams += `&col_${index+1}_${typeKey}=${value}`;
    });
  }else if(appScriptUrl.type === "feedback"){
    let {featureType, feedback, email} = finalData;
    appScriptUrlWithParams += `?featureType=${featureType}&feedback=${feedback}&email=${email}`;
  }else if(appScriptUrl.type === "quiz"){
    appScriptUrlWithParams += `?sheetName=${finalData.sheetName}&correctAnswerRatio=${finalData.correctAnswerRatio}`;
    _.keys(finalData.quizResult).map( (questionIndexKey, index) => {
      let value = "X";
      if(finalData.quizResult[questionIndexKey])
        value = "O";
      appScriptUrlWithParams += `&col_${index+1}_${questionIndexKey}=${value}`;
    });
  }

  const addData = httpsCallable(functions, 'triggerAppScriptWithData');
  let data = {appScriptUrlWithParams};

  addData(data).then( (result) => {})
}

export const sendVerificationEmail = () => {
  return new Promise ( (resolve) => {
    sendEmailVerification(auth.currentUser).then(() => {
      resolve();
    });
  })
}

export const signUp = (e) => {
  e.preventDefault();
  let email = e.target.elements.email.value;
  let password = e.target.elements.password.value;

  return new Promise( (resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        createSettings().then( ( ) => {});
        sendVerificationEmail().then( () => {
          resolve({message: '인증 메일을 보냈습니다'})
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        reject({message: "에러가 발생했습니다"});
      });
  })
}

export const sendPassRestEmail = (e) => {
  e.preventDefault();
  let email = e.target.elements.email.value;

  return new Promise( (resolve, reject) => {
    sendPasswordResetEmail(auth, email).then( () => {
      resolve({message: '인증 메일을 보냈습니다'})
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      reject({message: "에러가 발생했습니다"});
    });
  })
}

export const login = (e) => {
  e.preventDefault();
  let email = e.target.elements.email.value;
  let password = e.target.elements.password.value;

  return new Promise( (resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password).then( () => {
      resolve();
    }).catch((error) => {
      reject({error});
    })
  })
}

export const logout = () => {
  return new Promise(( resolve, reject) => {
    signOut(auth).then( () => {
      resolve({message: "로그아웃 되었습니다"})
    });
  });
}

export const addDoc = (collectionName, docName, data) => {
  return new Promise( (resolve, reject) => {
    setDoc(doc(db, collectionName, docName), data).then( () => {
      resolve();
    });
  });
}

export const removeDoc = (collectionName, docId) => {
  return new Promise( (resolve, reject) => {
    deleteDoc(doc(db, collectionName, docId)).then( () => {
      resolve();
    });
  });
}

export const updateDocument = (collectionName, docId, data) => {
  return new Promise( (resolve, reject) => {
    updateDoc(doc(db, collectionName, docId), data).then( () => {
      resolve();
    });
  });
}
