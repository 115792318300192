import {useState, useEffect, useRef} from "react";
import _ from "underscore";
import CurrencyInput from "react-currency-input-field";
import "./CapitalNeeded.scss";
import {Tooltip} from "react-tooltip";
import {convertToKoreanNumber, formatCurrency} from "../helper/currencyHelper";
import {useAnalyticsEventTracker} from "../helper/googleAnalytics";
import {addOrRemoveClassNameOnHtmlTag} from "../helper/generalHelper";
import MetaTag from "../general/component/MetaTag";
import SubscribeAndDownload from "./SubscribeAndDownload";
import assetAllocationImg from "../assets/img/asset_allocation.jpg";
import investorTypeImg from "../assets/img/investor_type_cover_optimized.jpg";
import FeedbackForm from "../general/component/FeedbackForm";
import {auth} from "../helper/firebaseHelper";

const initialCapData = {
  currentAge: {icon: "🍀", title: "현재 나이", value: 30, description: "현재 나이를 입력해주세요", type: 'age', unit: 1},
  retireAge: {icon: "🍂", title: "희망 은퇴 나이", value: 45, description: "희망 은퇴나이를 입력해주세요", type: 'age', unit: 1},
  passAwayAge: {icon: "⚰️", title: "️ 희망 수명 나이", value: 100, description: "희망하는 수명을 입력해주세요", type: 'age', unit: 5},
  monthlyExpense: {icon: "🛒", title: "희망 월 생활비", value: 3000000, description: "희망 월 생활비를 현재 물가 기준으로 적어주세요. 투자 성과 계산시에는 연 물가 상승률을 반영합니다", type: 'currency', unit: 500000},
  currentCap: {icon: "💰", title: "투자 시작금", value: 100000000, description: "현재 자산, 혹은 투자금 시작금으로 쓸 수 있는 금액을 입력해주세요", type: 'currency', unit: 5000000},
  monthlySaving: {icon: "💰", title: "월 투자금", value: 1000000, description: "꾸준히 투자할 수 있는 월 금액을 입력해주세요. 계산시에는 연 물가 상승률을 반영하게 됩니다", type: 'currency', unit: 500000},
  nominalReturn: {icon: "📈", title: "희망 명목 수익률", value: 10, description: "희망 연 투자 수익률을 입력해주세요. 참고로 워렌버핏의 평균 연 수익률은 19.8%이며, 실질 수익률은 연 물가 상승률을 제외한 금액입니다", type: 'rate', unit: 1},
  yearlyPriceRiseRate: {icon: "📈", title: "연 물가 상승률", value: 3, description: "보통 3%로 보시면 되며, 월 투자금, 생활비도 물가 상승률을 반영하여 매년 계산됩니다", type: 'rate', unit: 1},
}

const initialResult = {
  isBankrupt: false,
  bankruptAge: 0,
}

const CapitalNeeded = ({theme, feedbackAppScriptUrl, onThemeButtonClick}) => {
  const [capData, setCapData] = useState({...initialCapData});
  const [result, setResult] = useState({...initialResult});
  const [displayPlanner, setDisplayPlanner] = useState(true);

  const beforeRetireTableHeaderRef = useRef();
  const beforeRetireTableBodyRef = useRef();
  const afterRetireTableHeaderRef = useRef();
  const afterRetireTableBodyRef = useRef();
  const gaEventTracker = useAnalyticsEventTracker('Planning');

  let csvData = [];
  let finalCapSoFar = 0;
  let yearCount = 0;
  let bankruptAge = 0;
  let yearSoFar = (new Date).getFullYear()-1;

  const tableColumns = {
    beforeRetire: [
      {title: "투자 해수", className: ""},
      {title: "연도", className: ""},
      {title: "나이", className: ""},
      {title: "연초 자산", className: "currency"},
      {title: "연 수익", sub: `연초 기준 ${capData.nominalReturn.value}%`, className: "currency"},
      {title: "연 저축액", sub: `물가 상승 ${capData.yearlyPriceRiseRate.value}% 반영`, className: "currency"},
      {title: "최종 연말 자산", sub: `연초+연수익+연저축`, className: "currency year-end-capital"},
      {title: "연 생활비", sub: `물가 상승 ${capData.yearlyPriceRiseRate.value}% 반영`, className: "currency beside expense"},
    ],
    afterRetire: [
      {title: "연도", className: ""},
      {title: "나이", className: ""},
      {title: "연초 자산", className: "currency"},
      {title: "연 생활비", sub: `물가 상승 ${capData.yearlyPriceRiseRate.value}% 반영`, className: "currency"},
      {title: "연 생활비 차감 후 자산", className: "currency"},
      {title: "연 수익", sub: `차감 후 기준 ${capData.nominalReturn.value}%`, className: "currency"},
      {title: "최종 연말 자산", sub: `연초-연생활비+연수익`, className: "currency real-final-capital"},
    ]
  }

  useEffect( () => {
    setResult(_.clone(initialResult));

  }, [capData, ]);

  useEffect(() => {
    if(displayPlanner){
      addOrRemoveClassNameOnHtmlTag("add", "show-planner");
    }else{
      addOrRemoveClassNameOnHtmlTag("remove", "show-planner");
    }
  }, [displayPlanner])

  const setCsvDataHeader = () => {
    let csvCapDataTitles = [];
    let csvCapDataValue = [];

    _.keys(capData).map( (key, index) => {
      csvCapDataTitles.push(capData[key].title);
      if(capData[key].type === 'rate'){
        csvCapDataValue.push(capData[key].value/100);
      }else{
        csvCapDataValue.push(capData[key].value);
      }


      if(_.keys(capData).length === index + 1){
        csvData.push(csvCapDataTitles);
        csvData.push(csvCapDataValue);
      }
    });
  }

  const updateCapData = (key, value, property='value') => {
    let newCapData = _.clone(capData);
    if(!value)
      value = 0;
    newCapData[key][property] = parseInt(value);
    setCapData(newCapData);
  }

  const renderCurrencyAndText = (num) => {
    return (
      <div className={'currency-and-text'}>
        <span className={'currency'}>{formatCurrency(num)}</span>
        <span className={'text'}>{num < 0 ? "-" : ""}{convertToKoreanNumber(Math.abs(num))}</span>
      </div>
    )
  }

  const renderUnit = (capDataItem) => {
    let unitString = capDataItem.unit;
    if(capDataItem.type === 'age')
      unitString += "세";
    else if(capDataItem.type === 'currency')
      unitString = convertToKoreanNumber(capDataItem.unit);
    else if(capDataItem.type === 'rate')
      unitString += "%";
    return unitString
  }

  const renderCapItem = (key, index) => {
    return (
      <div className={'cap-data-item'} key={index}>
        <div className={'item-header'}>
          <a className={'tooltip-trigger title-wrapper'} data-tooltip-id={key} data-tooltip-place="top">
            <h5 className={'item-title'}>
              {/*{capData[key].icon} */}
              {capData[key].title}
            </h5>
            <div className={'question'}>?</div>
          </a>
          <Tooltip id={key} className={'tooltip'} place={'top'}>
            <div className={'tooltip-content-wrapper'}>{capData[key].description}</div>
          </Tooltip>
          <span className={'unit'}>{renderUnit(capData[key])} 단위</span>
        </div>

        <div className={"cap-data-input-container"}>
          <CurrencyInput className={'input-main'}
                         prefix={capData[key].type === 'currency' ? "￦" : ""}
                         suffix={capData[key].type === 'rate' ? "%" : (capData[key].type === 'age' ? '세' : '')}
                         disableGroupSeparators={capData[key].type !== 'currency'}
                         defaultValue={capData[key].value}
                         value={capData[key].value}
                         decimalsLimit={2}
                         onValueChange={ (value, name, values) => {
                           gaEventTracker(key);
                           updateCapData(key, value);
                         }}/>
          <button className={"btn-change-by-unit btn-minus btn-fill c-el2"}
                  onClick={ () => {
                    let newValue = capData[key].value - capData[key].unit;
                    if(newValue < 0) newValue = 0;
                    updateCapData(key, newValue);
                  }}>-</button>
          <button className={"btn-change-by-unit btn-plus btn-fill c-el2"}
                  onClick={() => {
                    let newValue = capData[key].value + capData[key].unit;
                    updateCapData(key, newValue);
                  }}>+</button>
        </div>

        <div className="container-sub-info">
          {capData[key].type === 'currency' ? (
            <div className="readable-currency">
              <span className={'korean-currency'}>{convertToKoreanNumber(capData[key].value)}</span>
              {(key === "monthlySaving" || key === "monthlyExpense") ? (
                <span>(연 {convertToKoreanNumber(capData[key].value * 12)})</span>
              ) : "" }
            </div>
          ) : ""}

        </div>
      </div>
    )
  }

  const renderTableHeader = (tableType) => {
    let tableColumnTitleArray = [];
    if(!csvData.length){
      setCsvDataHeader();
    }
    return (
      <thead>
      <tr>
        {tableColumns[tableType].map( (column, index) => {
          tableColumnTitleArray.push(`${column.title} ${column.sub ? column.sub : ''}`);
          if(tableColumns[tableType].length === index+1){
            csvData.push(tableColumnTitleArray);
          }
          return (
            <th className={column.className} key={index}>{column.title}{column.sub ? (<><br/><span>{column.sub}</span></>) : ''}</th>
          )
        })}
      </tr>
      </thead>
    )
  }

  let currentScrollingRef = "";
  const matchScroll = (rootRef, targetRef) => {
    if(currentScrollingRef === rootRef){
      targetRef.current.scrollLeft = rootRef.current.scrollLeft;
    }
  }

  const setCurrentScrollingRef = (ref) => {
    currentScrollingRef = ref;
  }

  const renderTableRow = (tableType, age, index) => {
    if(tableType === 'beforeRetire'){
      let currentCap = capData.currentCap.value;
      let yearSaving =  (capData.monthlySaving.value * 12) * Math.pow(1+(capData.yearlyPriceRiseRate.value/100), yearCount);
      let yearExpense =  (capData.monthlyExpense.value * 12) * Math.pow(1+(capData.yearlyPriceRiseRate.value/100), yearCount);

      let yearStartCap = index !== 0 ? finalCapSoFar : currentCap;
      let nominalReturn = yearStartCap * capData.nominalReturn.value/100;

      finalCapSoFar = yearStartCap + nominalReturn + yearSaving;
      yearCount++;
      yearSoFar++;

      csvData.push([index + 1, yearSoFar, age, yearStartCap, nominalReturn, yearSaving, finalCapSoFar, yearExpense]);
      return (
        <tr className={`working-year-row`} key={index}>
          <td>{index + 1}</td>
          <td>{yearSoFar}</td>
          <td>{age}세</td>
          <td className={'currency'}>{renderCurrencyAndText(yearStartCap)}</td>
          <td className={'currency'}>{renderCurrencyAndText(nominalReturn)}</td>
          <td className={'currency'}>{renderCurrencyAndText(yearSaving)}</td>
          <td className={'currency year-end-capital'}>{renderCurrencyAndText(finalCapSoFar)}</td>
          <td className={'currency beside expense'}>{renderCurrencyAndText(yearExpense)}</td>
        </tr>
      )
    }else{
      let yearExpense =  (capData.monthlyExpense.value * 12) * Math.pow(1+(capData.yearlyPriceRiseRate.value/100), yearCount);
      let yearStartCap = index !== 0 ? finalCapSoFar : finalCapSoFar;
      let yearRest = finalCapSoFar - yearExpense;

      let nominalReturn = yearRest * capData.nominalReturn.value/100;

      let yearFinalCap = yearRest + nominalReturn;
      let prevYearGap = yearFinalCap - finalCapSoFar;
      finalCapSoFar = yearFinalCap;
      yearCount++;

      if(finalCapSoFar < 0 && !result.isBankrupt && !bankruptAge){
        bankruptAge = age - 1;
        setResult({isBankrupt: true, bankruptAge});
      }
      yearSoFar++;

      csvData.push([yearSoFar, age, yearStartCap, yearExpense, yearRest, nominalReturn, finalCapSoFar]);
      return (
        <tr className={`working-year-row ${prevYearGap > 0 ? 'increased' : 'decreased'} ${yearFinalCap < 0 ? 'bankruptcy' : ''}`} key={index}>
          <td>{yearSoFar}</td>
          <td>{age}세</td>
          <td className={'currency'}>{renderCurrencyAndText(yearStartCap)}</td>
          <td className={'currency expense'}>{renderCurrencyAndText(yearExpense)}</td>
          <td className={'currency'}>{renderCurrencyAndText(yearRest)}</td>
          <td className={'currency'}>{renderCurrencyAndText(nominalReturn)}</td>
          <td className={`currency year-end-capital real-final-capital ${prevYearGap < 0 ? 'has-warning-tooltip' : ''}`}>
            {prevYearGap < 0 ? (
              <div className='warning-tooltip'>
                <a className={'tooltip-trigger'} data-tooltip-id={`warning-${index}`} data-tooltip-place="top">
                  <div className={'alert-icon'}>!</div>
                </a>
                <Tooltip id={`warning-${index}`} className={'tooltip'} place={'top'}>
                  {yearFinalCap < 0 ? "자산이 바닥나서 생활이 불가합니다 😢" : "수익보다 생활비 지출이 많아지면서 자산이 감소합니다! 📉"}
                </Tooltip>
              </div>
            ) : ""}
            {renderCurrencyAndText(finalCapSoFar)}
          </td>
        </tr>
      )
    }
  }

  return (
    <div className={`page capital-needed ${displayPlanner ? 'show-planner' : 'hide-planner'}`}>
      <MetaTag title={"경제적 자유 계산기"} description={"내 경제적 자유를 위해서 얼마나 필요한지 계산해보세요!"}/>
      <div className={`container-planner ${result.isBankrupt ? 'bankruptcy' : ''}`}>
        <div className={'section-header'}>
          <div className={'title-line'}>
            <h4>💰경제적 자유 플래너</h4>
            <button className={'btn-fill c-el1 s-sharp btn-action btn-hide-planner'} onClick={ () => {setDisplayPlanner(!displayPlanner)}}>
              <span className={'text hide'}>접기</span>
              <span className={'text see-result'}>결과 보기</span>
            </button>
          </div>

          <div className={'status-line'}>
            <div className={'label'}>자유 가능 여부</div>
            <div className={`container-status`}>
              <a className={'tooltip-trigger status-light'} data-tooltip-id={'status-light'} data-tooltip-place="top">
                {/*<div className={'ball'}/>*/}
                <span>{result.isBankrupt ? "자유 불가" : "자유 가능"}</span>
              </a>
              <Tooltip id={'status-light'} className={'tooltip'} place={'bottom'}>
                <div className={'tooltip-content-wrapper'}>{result.isBankrupt ? "현재 정보로는 경제적 자유 달성이 불가합니다. 정보를 수정해보세요 😢" : "현재 입력 값으로 경제적 자유 달성 가능합니다! 😌"}</div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={`section-body`}>
          {_.keys(initialCapData).map( (key, index) => renderCapItem(key, index))}
        </div>
      </div>
      <div className={'container-tables'}>
        <button className={'btn-fill c-el1 s-pill btn-action btn-show-planner'} onClick={ () => {setDisplayPlanner(true)}}>
          <span className={'text hide'}>계획 수정</span>
        </button>
        {!auth.currentUser && <SubscribeAndDownload csvData={csvData} capData={capData}/>}

        <div className="container-summary">
          <div className={'summary'}>
            <h1>{capData.currentAge.value}세, 투자를 시작합니다!</h1>
            {renderCurrencyAndText(capData.currentCap.value)}위 현재 자산으로 희망 은퇴 {capData.retireAge.value}세 까지 {capData.retireAge.value - capData.currentAge.value}년 동안 투자를 합니다!
          </div>
        </div>
        <div className={"section-table"}>
          <div className={'description'}>
            단순하고 보수적인 계산을 위해 매년 연 투자금을 더하지 않은 상태에서 명목 수익률을 계산합니다
          </div>

          <div className={'table-wrapper sticky-header'}
               ref={beforeRetireTableHeaderRef}
               onMouseOver={ () => {setCurrentScrollingRef(beforeRetireTableHeaderRef)}}
               onTouchStart={ () => {setCurrentScrollingRef(beforeRetireTableHeaderRef)}}
               onScroll={ (e) => {
                 matchScroll(beforeRetireTableHeaderRef, beforeRetireTableBodyRef);
               }}>
            <table>
              {renderTableHeader('beforeRetire')}
            </table>
          </div>

          <div className={'table-wrapper body'}
               ref={beforeRetireTableBodyRef}
               onMouseOver={ () => {setCurrentScrollingRef(beforeRetireTableBodyRef)}}
               onTouchStart={ () => {setCurrentScrollingRef(beforeRetireTableBodyRef)}}
               onScroll={ (e) => {
                 matchScroll(beforeRetireTableBodyRef, beforeRetireTableHeaderRef);
               }}>
            <table>
              <tbody>
              {_.range(capData.currentAge.value, capData.retireAge.value).map( (age, index) => {
                return renderTableRow('beforeRetire', age,index);
              })}
              </tbody>
            </table>
          </div>
        </div>

        {!auth.currentUser && <SubscribeAndDownload csvData={csvData} capData={capData}/>}

        <div className={`container-summary ${result.isBankrupt ? 'negative' : ''}`}>
          <h1>{capData.retireAge.value}세 은퇴{result.isBankrupt ? '..!' : '를 축하합니다!🎉'}</h1>

          {result.isBankrupt ? (
            <>
              <span>하지만 아쉽게도..</span>
              {renderCurrencyAndText(finalCapSoFar)}위 투자 결과 자산으로는 {result.bankruptAge}세 이후로 생활을 유지할 수 없습니다 😢
            </>
          ) : (
            <>
              {renderCurrencyAndText(finalCapSoFar)}위 투자 결과 자산으로 {capData.passAwayAge.value}세 까지 {capData.passAwayAge.value - capData.retireAge.value}년 동안 생활이 가능합니다! 👍
            </>
          )}
          <span className={'sub'}>스크롤을 내려 확인해주세요</span>
        </div>

        <div className={'section-table'}>
          <div className={'description'}>
            단순하고 보수적인 계산을 위해 연지출을 먼저 차감한 후 명목 수익률을 계산합니다
          </div>

          <div className={'table-wrapper sticky-header'}
               ref={afterRetireTableHeaderRef}
               onMouseOver={ () => {setCurrentScrollingRef(afterRetireTableHeaderRef)}}
               onTouchStart={ () => {setCurrentScrollingRef(afterRetireTableHeaderRef)}}
               onScroll={ (e) => {
                 matchScroll(afterRetireTableHeaderRef, afterRetireTableBodyRef);
               }}>
            <table>{renderTableHeader('afterRetire')}</table>
          </div>

          <div className={'table-wrapper body'}
               ref={afterRetireTableBodyRef}
               onMouseOver={ () => {setCurrentScrollingRef(afterRetireTableBodyRef)}}
               onTouchStart={ () => {setCurrentScrollingRef(afterRetireTableBodyRef)}}
               onScroll={ (e) => {
                 matchScroll(afterRetireTableBodyRef, afterRetireTableHeaderRef);
               }}>
            <table>
              <tbody>
              {_.range(capData.retireAge.value, capData.passAwayAge.value).map( (age, index) => {
                return renderTableRow('afterRetire', age, index);
              })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={`container-summary ${result.isBankrupt ? 'negative' : ''}`}>
          <h1>{capData.passAwayAge.value}세, {result.isBankrupt ? '불편히 잠들다..!😢' : '평안히 잠들다 😇'}</h1>
          {result.isBankrupt ? (
            <>
              평안한 노후를 위해 계획을 수정해보세요 😢
            </>
          ) : (
            <>
              {renderCurrencyAndText(finalCapSoFar)} 남은 금액은 후대를 위해 남겨두고 평안에 잠드세요 😇
            </>
          )}
        </div>
        <div className={'next-step'}>
          <a className={'btn-post btn-fill c-el1'} href="https://sailingcapitalism.com/%ed%88%ac%ec%9e%90-%ed%8f%ac%ed%8a%b8%ed%8f%b4%eb%a6%ac%ec%98%a4-%eb%82%b4%ea%b2%8c-%eb%a7%9e%eb%8a%94-%ec%9e%90%ec%82%b0-%ea%b5%ac%ec%84%b1%ec%9d%80/" target="_blank">
            <div className='text-wrapper'>
              <h3>계획 끝! 다음은 뭘해야할까요?</h3>
              <p className={'description'}>계획을 다 세우셨다면 본인 상황에 맞는 자산 배분 전략이 뭔지 알아봐야해요!<br/>이미 글을 작성해놨으니 본인 수익률에 맞는 전략은 무엇일지 확인해보세요!</p>
              <p className={"cta"}>👉 글 읽으러가기!</p>
            </div>
            <img src={assetAllocationImg} alt=""/>
          </a>
          <a className={'btn-post btn-fill c-el1'} href="https://tools.sailingcapitalism.com/examination/investor-type">
            <div className='text-wrapper'>
              <h3>나의 투자 성향이 궁금하다면?</h3>
              <p className={'description'}>나는 투자 성향은 방어형일까요 공격형일까요?<br/>직접 만든 성향 테스트인데 한 번 해보세요!</p>
              <p className={"cta"}>👉 테스트 해보러가기!</p>
            </div>
            <img src={investorTypeImg} alt=""/>
          </a>
        </div>
        {!auth.currentUser && <SubscribeAndDownload csvData={csvData} capData={capData}/>}
        {/*<FeedbackForm featureType={"calculator"} appScriptUrl={feedbackAppScriptUrl}/>*/}
      </div>
    </div>
  )
}

export default CapitalNeeded;
