let rootElement = document.getElementsByTagName('html')[0];

export const addOrRemoveClassNameOnHtmlTag = (addOrRemove, className) => {
  if(addOrRemove === 'add'){
    rootElement.classList.add(className);
  }else{
    rootElement.classList.remove(className);
  }
}
export const RES_TYPE = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
}

export const copyText = (text) => {
  return new Promise((resolve, reject) => {
    navigator.clipboard.writeText(text);
    resolve();
  })
}

export const themeLocalStorageKey = 'sailing-tools-color-theme';
export const themeValue = {default: "dark", opposite: 'light'};

export const changeTheme = () => {
  let rootElement = document.getElementsByTagName("html")[0];

  let currentColorTheme = localStorage.getItem(themeLocalStorageKey);
  return new Promise( (resolve, reject) => {
    if(currentColorTheme === themeValue.default){
      localStorage.setItem(themeLocalStorageKey, themeValue.opposite);
      rootElement.classList.replace(themeValue.default, themeValue.opposite);
      resolve(themeValue.default);
    }else{
      localStorage.setItem(themeLocalStorageKey, themeValue.default);
      rootElement.classList.replace(themeValue.opposite, themeValue.default);
      resolve(themeValue.default);
    }
  });
}

export const getRandomColor = () => Math.floor(Math.random()*16777215).toString(16);

export const checkLocalStorageAndSetDefault = (localStorageKey, defaultValue) => {
  let currentLocalStorageValue = localStorage.getItem(localStorageKey);
  if (!currentLocalStorageValue) {
    currentLocalStorageValue = defaultValue;
    localStorage.setItem(localStorageKey, currentLocalStorageValue);
  }
  let htmlElement = document.getElementsByTagName('html')[0];
  htmlElement.classList.add(currentLocalStorageValue);
}


export const getRandomId = (length=20) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
